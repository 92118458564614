<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <tbody v-if="!tableProps.loading">
    <tr
      v-for="(row, index) in tableProps.data"
      :key="index"
    >
      <td
        v-for="column in tableProps.columns"
        :key="column.field"
      >
        <component
          :is="column.component"
          v-if="column.component !== undefined"
          :row="row"
          :column="column"
        />
        <bootstrap-table-data
          v-else
          :row="row"
          :column="column"
        />
      </td>
    </tr>
    <tr
      v-if="tableProps.data.length === 0"
    >
      <td
        :colspan="tableProps.columns.length"
        class="text-center"
      >
        Nessun risultato
      </td>
    </tr>
  </tbody>
  <tbody v-else>
    <tr>
      <td
        :colspan="tableProps.columns.length"
        class="text-center"
      >
        <span class="spinner-border spinner-border-sm" />
      </td>
    </tr>
  </tbody>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {BootstrapTableProps} from '@/types/entities';
import {getProperty} from 'dot-prop';
import BootstrapTableData from '@/components/BootstrapTable/BootstrapTableData.vue';

export default defineComponent({
  name: 'BootstrapTableBody',
  components: {BootstrapTableData},
  props: {
    tableProps: {
      type: Object as PropType<BootstrapTableProps>,
      required: true,
    },
  },
  methods: {
    getProperty(row: any, field: string) {
      return getProperty(row, field);
    },
  },
});

</script>

<style lang="scss" scoped>
 table {
   border-collapse: collapse;
 }
 td {
   padding: 1.0rem!important;
 }
</style>
