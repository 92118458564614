/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {
  Filter,
  Pagination,
  ProductInterface,
  ProductUpdateRequestInterface,
  Sorting,
  VehicleFrameInterface,
} from '@/types/entities';

export default class ProductData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<ProductInterface[]> {
    const url = new URL(`${this.apiUrl}/product`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(url, signal);
  }

  get(productId: number): Promise<ProductInterface> {
    const url = new URL(`${this.apiUrl}/product/${productId}`);
    return this.fetchWithAuth(url);
  }

  getVehicleFrameList(productId: number, pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<VehicleFrameInterface[]> {
    const url = new URL(`${this.apiUrl}/product/${productId}/vehicle-frame`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(url, signal);
  }

  create(): Promise<{id:string, code:string}> {
    const url = new URL(`${this.apiUrl}/product`);
    return this.fetchWithAuth(url, {
      method: 'POST',
    });
  }

  update(productId: number, product: ProductUpdateRequestInterface): Promise<ProductInterface> {
    const url = new URL(`${this.apiUrl}/product/${productId}`);

    return this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(product),
    });
  }
}
