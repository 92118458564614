/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import OrderData from '@/services/data/OrderData';

export default class LabelsDownloader {
  private orderData = new OrderData();
  async downloadLabels() {
    const blob = await this.orderData.downloadLabels();
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = url;
    const date = new Date().toISOString().slice(0, 19).split('-').join('_').split('T').join('_').split(':').join('_');
    link.download = 'orders_labels_' + date;
    window.document.body.appendChild(link);
    link.click();
  }

  async downloadAddresses() {
    const blob = await this.orderData.downloadAddresses();
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = url;
    const date = new Date().toISOString().slice(0, 19).split('-').join('_').split('T').join('_').split(':').join('_');
    link.download = 'orders_addresses_' + date;
    window.document.body.appendChild(link);
    link.click();
  }
  async downloadOrders(year: number | undefined) {
    if (!year) {
      year = 0;
    }
    const blob = await this.orderData.downloadOrders(year);
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = url;
    const date = new Date().toISOString().slice(0, 19).split('-').join('_').split('T').join('_').split(':').join('_');
    link.download = 'orders_' + date;
    window.document.body.appendChild(link);
    link.click();
  }
}
