<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="pt-2 pb-1">
    <ul class="nav nav-pills flex-column">
      <li
        v-for="link in links"
        :key="link.name"
        class="nav-item"
      >
        <router-link
          class="nav-link link-dark rounded-4 py-2"
          :to="{'name': link.routeName}"
        >
          <span
            class="bi px-2"
            :class="[`bi-${link.icon}`]"
          />
          {{ link.name }}
        </router-link>
      </li>
    </ul>
    <hr class="text-white my-1">
    <button
      v-if="userStore.userOperations.menuItems?.logout"
      class="btn btn-link text-secondary text-decoration-none"
      @click="logout"
    >
      <span
        class="bi bi-door-closed"
      />
      Logout
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useSessionStore} from '@/stores/SessionStore';
import {useUserStore} from '@/stores/UserStore';
import NavigationService from '@/services/NavigationService';
import NavigationLink from '@/types/NavigationLink';

export default defineComponent({
  name: 'MainActions',
  setup: () => {
    return {
      navigationService: new NavigationService(),
      navigationLinksToDisplay: [
        'Profilo',
        'Credenziali',
        'Indirizzi',
      ],
      sessionStore: useSessionStore(),
      userStore: useUserStore(),
    };
  },
  computed: {
    links: function() {
      return this.navigationService.getNavigationLinks()
          .filter((link: NavigationLink) => this.navigationLinksToDisplay.includes(link.name));
    },
  },
  methods: {
    logout: function() {
      this.sessionStore.logout();
      this.$router.push({name: 'LoginPage'});
      window.location.reload();
    },
  },
});
</script>

<style scoped lang="scss">
.nav-link, .btn-link {
  font-size: 0.8rem;
}
hr {
  border: 2px solid #fff;
}
</style>
