/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

export default class NavigationLink {
  public readonly name: string;
  public readonly routeName: string;
  public readonly title: string;
  public readonly icon: string;
  public readonly userOperation: string|null;

  constructor(name: string, routeName: string, title: string, icon: string, userOperation: string|null = null) {
    this.name = name;
    this.title = title;
    this.icon = icon;
    this.routeName = routeName;
    this.userOperation = userOperation;
  }
}
