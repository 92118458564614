/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {Filter, NewsInterface, Pagination, Sorting} from '@/types/entities';
import NewsData from '@/services/data/NewsData';
import News from '@/types/News';

export const useNewsStore = defineStore('NewsStore', {
  state: () => {
    return {
      news: [] as News[],
      currentNews: {} as News,
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting) {
      try {
        const news = await (new NewsData()).list(pagination, filters, sorting);
        this.news = news.map((newsItem: NewsInterface) => new News(newsItem));
      } catch (error) {
        console.error(error);
      }
    },
    async get(newsId:number) {
      this.currentNews = new News(await (new NewsData()).get(newsId));
    },
    clear() {
      this.news = [];
      this.currentNews = {} as News;
    },
  },
});
