/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {Filter, NewsInterface, NewsUpsertRequestInterface, Pagination, Sorting} from '@/types/entities';

export default class NewsData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting): Promise<NewsInterface[]> {
    const url = new URL(`${this.apiUrl}/news`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchWithAuth(url);
  }

  get(newsId:number): Promise<NewsInterface> {
    const url = new URL(`${this.apiUrl}/news/${newsId}`);
    return this.fetchWithAuth(url);
  }

  create(data: NewsUpsertRequestInterface): Promise<{title:string, content:string}> {
    const url = new URL(`${this.apiUrl}/news`);
    return this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  update(newsId: number, data:NewsUpsertRequestInterface): Promise<{id:string, title:string}> {
    const url = new URL(`${this.apiUrl}/news/${newsId}`);
    return this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  delete(newsId: number): Promise<void> {
    const url = new URL(`${this.apiUrl}/news/${newsId}`);
    return this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}
