<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="btn-group">
    <router-link
      v-if="userStore.userOperations.order?.listView.editViewPopupCourierAndTrackIdButton"
      :to="{name: 'OrderEdit', params: {orderId: row.id}}"
      class="btn btn-sm btn-link text-dark"
    >
      <span class="bi bi-box-seam" />
    </router-link>
    <router-link
      v-if="userStore.userOperations.order.listView?.editViewPopupStatusButton"
      :to="{name: 'OrderEdit', params: {orderId: row.id}}"
      class="btn btn-sm btn-link text-dark"
    >
      <span class="bi bi-hourglass-split" />
    </router-link>
    <router-link
      v-if="userStore.userOperations.order.listView?.detailView"
      :to="{name: 'OrderDetail', params: {orderId: row.id}}"
      class="btn btn-sm btn-link text-dark"
    >
      <span class="bi bi-search" />
    </router-link>
    <div>
      <span
        v-if="userStore.currentUser.role == 'ROLE_GREENKAR' && row.newDocument"
        class="float-end bi bi-file-earmark-text fs-5 text-red1 ps-2"
      />
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Order from '@/types/Order';
import {Column} from '@/types/entities';
import {useUserStore} from '@/stores/UserStore';

export default defineComponent({
  name: 'OrderActions',
  props: {
    row: {
      type: Object as PropType<Order>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
});
</script>
