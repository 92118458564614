<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <base-widget
    title="Ordini"
    icon="file-earmark-ruled"
    route-name="OrderList"
    route-icon="arrow-right"
  >
    <template #default>
      <div class="overflow-scroll d-block">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                Data
              </th>
              <th scope="col">
                Stato
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orderStore.orders"
              :key="order.id"
            >
              <td>
                {{ order.number }}
              </td>
              <td>
                {{ order.formattedOrderDate }}
              </td>
              <td>
                <order-status :order="order" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </base-widget>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseWidget from '@/components/Dashboard/BaseWidget.vue';
import {useOrderStore} from '@/stores/OrderStore';
import OrderStatus from '@/components/Dashboard/OrderStatus.vue';

export default defineComponent({
  name: 'OrdersWidget',
  components: {OrderStatus, BaseWidget},
  setup() {
    return {
      orderStore: useOrderStore(),
    };
  },
  async created() {
    const pagination = {
      page: 1,
      perPage: 10,
    };
    await this.orderStore.list(pagination);
  },
});
</script>

<style scoped lang="scss">
.overflow-scroll {
  height: 95%;
}
</style>
