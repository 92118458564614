import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-end" }
const _hoisted_2 = {
  key: 0,
  class: "bi bi-download"
}
const _hoisted_3 = {
  key: 1,
  class: "spinner-border spinner-border-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.userStore.userOperations.document?.listView.download)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "btn btn-sm btn-link text-dark",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadDocument && _ctx.downloadDocument(...args)))
        }, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2))
            : (_openBlock(), _createElementBlock("span", _hoisted_3))
        ]))
      : _createCommentVNode("", true)
  ]))
}