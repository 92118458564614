/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {DocumentInterface, DocumentUploadInterface, DocumentUpdateInterface, Filter, Pagination, Sorting} from '@/types/entities';

export default class DocumentData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<DocumentInterface[]> {
    const url = new URL(`${this.apiUrl}/document`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(url, signal);
  }

  get(documentId: number): Promise<DocumentInterface> {
    const url = new URL(`${this.apiUrl}/document/${documentId}`);
    return this.fetchWithAuth(url);
  }

  upload(data: DocumentUploadInterface, file: File|null = null, orderId?: number ): Promise<{id:string, filename:string}> {
    const url = new URL(`${this.apiUrl}/document`);

    const formData = new FormData();

    if (file) {
      formData.append('content', file);
    }

    if (orderId) {
      formData.append('order', String(orderId));
    }

    Object.keys(data).forEach((key) => {
      if (data[key as keyof DocumentUploadInterface] === undefined) {
        return;
      }
      formData.append(key, String(data[key as keyof DocumentUploadInterface]));
    });

    return this.fetchWithAuth(
        url,
        {
          method: 'POST',
          body: formData,
        },
    );
  }

  update(documentId: number, data: DocumentUpdateInterface): Promise<DocumentInterface> {
    const url = new URL(`${this.apiUrl}/document/${documentId}`);
    return this.fetchWithAuth(
        url,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
    );
  }

  download(documentId: number): Promise<Blob> {
    return this.downloadWithAuth(
        new URL(`${this.apiUrl}/document/${documentId}/download`),
        {
          method: 'GET',
        },
    );
  }
}
