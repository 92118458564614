<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="row h-50">
    <div class="col-4 h-100">
      <news-widget
        v-if="userStore.userOperations.dashboard?.newsWidget.view"
        class="h-100"
      />
    </div>
    <div class="col-4 h-100">
      <dca-widget
        v-if="userStore.userOperations.dashboard?.dcaWidget.view"
        class="h-100"
      />
    </div>
    <div class="col-4 h-100">
      <orders-widget
        v-if="userStore.userOperations.dashboard?.orderWidget.view"
        class="h-100"
      />
    </div>
  </div>
  <div class="row h-50 pt-4">
    <div class="col-12">
      <statistics-widget
        v-if="userStore.userOperations.dashboard?.statsWidget.orderCount || userStore.userOperations.dashboard?.statsWidget.averageProcessing"
        class="h-100"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/UserStore';
import NewsWidget from '@/components/Dashboard/NewsWidget.vue';
import DcaWidget from '@/components/Dashboard/DcaWidget.vue';
import OrdersWidget from '@/components/Dashboard/OrdersWidget.vue';
import StatisticsWidget from '@/components/Dashboard/Statistics/StatisticsWidget.vue';

export default defineComponent({
  name: 'DashboardPage',
  components: {StatisticsWidget, OrdersWidget, DcaWidget, NewsWidget},
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
});
</script>

<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
</style>
