import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-4 p-4" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_3 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["bi text-primary", [`bi-${_ctx.icon}`]])
        }, null, 2),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ]),
      (_ctx.routeName)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "btn btn-sm btn-gray2 rounded-pill px-2",
            to: {name: _ctx.routeName}
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(["bi", [`bi-${_ctx.routeIcon}`]])
              }, null, 2)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}