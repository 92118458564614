<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <tfoot>
    <tr>
      <td :colspan="modelValue.columns.length">
        <div class="d-flex justify-content-end">
          <nav>
            <ul class="pagination m-0">
              <li class="page-item">
                <button
                  class="page-link text-dark"
                  :disabled="modelValue.pagination.page === 1"
                  @click="decrementPage"
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              <li class="page-item">
                <button
                  class="page-link text-dark"
                  :disabled="modelValue.data.length < modelValue.pagination.perPage"
                  @click="incrementPage"
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </td>
    </tr>
  </tfoot>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {BootstrapTableProps} from '@/types/entities';

export default defineComponent({
  name: 'BootstrapTableFoot',
  props: {
    modelValue: {
      type: Object as PropType<BootstrapTableProps>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    incrementPage() {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        pagination: {
          ...this.modelValue.pagination,
          page: this.modelValue.pagination.page + 1,
        },
      });
    },
    decrementPage() {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        pagination: {
          ...this.modelValue.pagination,
          page: this.modelValue.pagination.page - 1,
        },
      });
    },
  },
});
</script>

