<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <router-link
    v-if="userStore.userOperations.order?.listView.detailView"
    :to="{name: 'OrderDetail', params: {orderId: row.id}}"
    class="text-decoration-none fw-semibold text-dark"
  >
    #<bootstrap-table-data
      :row="row"
      :column="column"
    />
  </router-link>
  <span v-else>
    #<bootstrap-table-data
      :row="row"
      :column="column"
    />
  </span>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Order from '@/types/Order';
import {Column} from '@/types/entities';
import BootstrapTableData from '@/components/BootstrapTable/BootstrapTableData.vue';
import {useUserStore} from '@/stores/UserStore';

export default defineComponent({
  name: 'OrderNumber',
  components: {BootstrapTableData},
  props: {
    row: {
      type: Object as PropType<Order>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
});
</script>
