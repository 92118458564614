/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {DocumentInterface} from '@/types/entities';
import {DateTime} from 'luxon';

export default class Document implements DocumentInterface {
  id: number;
  title: string;
  type: string | null;
  filename: string;
  mimeType: string;
  creationDate: string;
  orderNumber: number | null;
  vehicleFrameNum: number | null;
  fileSize: string | null;

  constructor(data: DocumentInterface) {
    this.id = data.id;
    this.title = data.title;
    this.type = data.type;
    this.filename = data.filename;
    this.mimeType = data.mimeType;
    this.creationDate = data.creationDate;
    this.orderNumber = data.orderNumber;
    this.vehicleFrameNum = data.vehicleFrameNum;
    this.fileSize = data.fileSize;
  }

  get formattedCreationDate(): string {
    if (!this.creationDate) {
      return '';
    }
    return DateTime.fromISO(this.creationDate).toLocaleString(DateTime.DATE_SHORT);
  }
}
