/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {useCartStore} from '@/stores/CartStore';
import {useDocumentStore} from '@/stores/DocumentStore';
import {useCategoryStore} from '@/stores/CategoryStore';
import {useNewsStore} from '@/stores/NewsStore';
import {useOrderStore} from '@/stores/OrderStore';
import {useProductStore} from '@/stores/ProductStore';
import {useUserStore} from '@/stores/UserStore';
import {useVehicleFrameStore} from '@/stores/VehicleFrameStore';
import {useSessionStore} from '@/stores/SessionStore';

export default class StoreCleanerService {
  private cartStore = useCartStore();
  private categoryStore = useCategoryStore();
  private documentStore = useDocumentStore();
  private newsStore = useNewsStore();
  private orderStore = useOrderStore();
  private productStore = useProductStore();
  private sessionStore = useSessionStore();
  private userStore = useUserStore();
  private vehicleFrameStore = useVehicleFrameStore();

  public clearStores() {
    this.cartStore.clear();
    this.categoryStore.clear();
    this.documentStore.clear();
    this.newsStore.clear();
    this.orderStore.clear();
    this.productStore.clear();
    this.sessionStore.clear();
    this.userStore.clear();
    this.vehicleFrameStore.clear();
  }
}
