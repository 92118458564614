<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="row mb-4">
    <div class="col-12">
      <order-payment-status
        v-if="paymentStatus"
        :payment-failed="paymentStatus === 'KO'"
      />
    </div>
  </div>

  <div class="d-flex justify-content-end mb-3">
    <button
      v-if="userStore.userOperations.order.listView.downloadAddressesButton"
      class="btn btn-lavanda text-white me-2"
      @click="downloadAddressesButton"
    >
      <span class="bi bi-download me-2" />
      Esporta ordini
    </button>
    <button
      v-if="userStore.userOperations.order.listView.downloadLabelsButton"
      class="btn btn-lavanda text-white me-2"
      @click="downloadLabelsButton"
    >
      <span class="bi bi-download me-2" />
      Esporta etichette
    </button>
    <div
      v-if="userStore.userOperations.order.listView.downloadOrdersButton"
      class="d-flex justify-content-end w-25 me-3"
    >
      <select
        v-model="yearOrderDownload"
        required
        class="form-select form-control rounded-4 shadow-none w-50"
      >
        <option
          value=""
          disabled
          selected
        >
          Seleziona anno
        </option>
        <option>
          Tutti gli ordini
        </option>
        <option>
          {{ firstYearOrderDownload }}
        </option>
        <option
          v-for="n in (lastYearOrderDownload - firstYearOrderDownload)"
          :key="n"
        >
          {{ firstYearOrderDownload + n }}
        </option>
      </select>
    </div>
    <button
      v-if="userStore.userOperations.order.listView.downloadOrdersButton"
      class="btn btn-lavanda text-white me-2"
      @click="downloadOrdersButton"
    >
      <span class="bi bi-download me-2" />
      Esporta ordini
    </button>
  </div>

  <div class="bg-gray1 d-inline-flex px-4 py-3 rounded-4 w-100">
    <bootstrap-table
      v-model="tableProps"
    />
    <router-view @order-updated="getOrderList" />
  </div>
</template>

<script lang="ts">
import {defineComponent, shallowRef} from 'vue';
import {BootstrapTableProps, PaymentStatusQueryParam} from '@/types/entities';
import {useOrderStore} from '@/stores/OrderStore';
import {useUserStore} from '@/stores/UserStore';
import BootstrapTable from '@/components/BootstrapTable/BootstrapTable.vue';
import OrderNumber from '@/components/Order/TableData/OrderNumber.vue';
import OrderDetails from '@/components/Order/TableData/OrderDetails.vue';
import OrderActions from '@/components/Order/TableData/OrderActions.vue';
import OrderStatus from '@/components/Order/TableData/OrderStatus.vue';
import LabelsDownloader from '@/services/LabelsDownloader';
import {OrderStatus as OrderStatusEnum} from '@/types/entities';
import OrderVehicleFrame from '@/components/Order/TableData/OrderVehicleFrame.vue';
import OrderProduct from '@/components/Order/TableData/OrderProduct.vue';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import OrderTracking from '@/components/Order/TableData/OrderTracking.vue';
import OrderPaymentStatus from '@/components/Order/OrderPaymentStatus.vue';

const OrderStatusOptions = [] as { value: string; label: string }[];

Object.keys(OrderStatusEnum).forEach((key) => {
  OrderStatusOptions.push({
    value: key,
    label: OrderStatusEnum[key as keyof typeof OrderStatusEnum],
  });
});

export default defineComponent({
  name: 'OrderList',
  components: {OrderPaymentStatus, BootstrapTable},
  setup() {
    return {
      orderStore: useOrderStore(),
      userStore: useUserStore(),
      yearOrderDownload: '',
      firstYearOrderDownload: 2023,
      lastYearOrderDownload: new Date().getFullYear(),
    };
  },
  data() : {
    created: boolean,
    abortController: AbortController,
    tableProps: BootstrapTableProps
    } {
    return {
      created: false,
      abortController: new AbortController(),
      tableProps: {
        columns: [
          {
            title: 'N. Ordine',
            field: 'number',
            sortable: true,
            filters: [
              {
                field: 'number',
                type: 'text',
              },
            ],
            component: shallowRef(OrderNumber),
          },
          {
            title: 'Cliente',
            field: 'businessName',
            fieldAlias: 'customer.businessName',
            sortable: true,
            filters: [
              {
                field: 'customerName',
                type: 'text',
              },
            ],
          },
          {
            title: 'Data Ordine',
            field: 'orderDate',
            fieldAlias: 'formattedOrderDate',
            sortable: true,
            filters: [
              {
                field: 'orderDate',
                type: 'date',
              },
            ],
          },
          {
            title: 'Data Evasione',
            field: 'processingDate',
            fieldAlias: 'formattedProcessingDate',
            sortable: true,
            filters: [
              {
                field: 'processingDate',
                type: 'date',
              },
            ],
          },
          {
            title: 'N. dettagli',
            field: 'orderDetails.length',
            sortable: false,
            component: shallowRef(OrderDetails),
          },
          {
            title: 'VIN telaio',
            field: 'vehicleFramesNum',
            sortable: false,
            component: shallowRef(OrderVehicleFrame),
            filters: [
              {
                field: 'vehicleFrameNum',
                type: 'text',
              },
            ],
          },
          {
            title: 'Prodotti',
            field: 'products',
            sortable: false,
            component: shallowRef(OrderProduct),
            filters: [
              {
                field: 'productCode',
                type: 'text',
              },
            ],
          },
          {
            title: 'Stato',
            field: 'status',
            sortable: true,
            filters: [
              {
                field: 'status',
                type: 'select',
                options: OrderStatusOptions,
              },
            ],
            component: shallowRef(OrderStatus),
          },
          {
            title: 'Tracking',
            field: 'trackId',
            sortable: false,
            filters: [
              {
                field: 'trackId',
                type: 'text',
              },
            ],
            component: shallowRef(OrderTracking),
          },
          {
            title: 'Azioni',
            field: 'actions',
            sortable: false,
            component: shallowRef(OrderActions),
          },
        ],
        data: [],
        loading: false,
        sorting: {
          field: 'number',
          direction: 'desc',
        },
        activeFilters: [],
        pagination: {
          page: 1,
          perPage: 10,
        },
      },
    };
  },
  computed: {
    paymentStatus() {
      return this.$route.query.payment_status as PaymentStatusQueryParam|null;
    },
  },
  watch: {
    'tableProps.sorting': async function() {
      this.orderStore.activeSorting = this.tableProps.sorting;
      await this.getOrderList();
    },
    'tableProps.pagination': async function() {
      this.orderStore.pagination = this.tableProps.pagination;
      await this.getOrderList();
    },
    'tableProps.activeFilters': async function() {
      this.orderStore.activeFilters = this.tableProps.activeFilters;
      if (this.created) this.tableProps.pagination.page = 1;
      await this.getOrderList();
    },
  },
  created: async function() {
    if (this.orderStore.activeSorting.field) {
      this.tableProps.sorting = this.orderStore.activeSorting;
    }
    if (this.orderStore.pagination.page) {
      this.tableProps.pagination = this.orderStore.pagination;
    }
    if (this.orderStore.activeFilters.length > 0) {
      this.tableProps.activeFilters = this.orderStore.activeFilters;
    }
    await this.getOrderList();
    this.created = true;
  },
  methods: {
    async getOrderList() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      try {
        this.tableProps.loading = true;
        await this.orderStore.list(this.tableProps.pagination, this.tableProps.activeFilters, this.tableProps.sorting, this.abortController.signal);
        this.tableProps.data = this.orderStore.orders;
        this.tableProps.loading = false;
      } catch (error) {
        ErrorHandlerService.handle(error);
      }
    },
    async downloadLabelsButton() {
      await (new LabelsDownloader()).downloadLabels();
    },
    async downloadAddressesButton() {
      await (new LabelsDownloader()).downloadAddresses();
    },
    async downloadOrdersButton() {
      await (new LabelsDownloader()).downloadOrders(Number(this.yearOrderDownload));
    },
  },
});
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
</style>
