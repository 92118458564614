<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="bg-white rounded-4 p-4">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="fw-bold d-flex align-items-center">
        <span
          class="bi bi-shield-exclamation text-lavanda me-2"
        />
        Termini e condizioni di vendita
      </h6>
    </div>
    <div class="row d-flex">
      <div
        class="terms container bg-gray2 rounded-3 overflow-scroll p-4"
        v-html="legalStore.termsConditions.cartTermsAndConditions"
      />
    </div>
    <div
      role="button"
      class="d-flex align-items-center mt-3"
      @click="toggleTermsAcceptance"
    >
      <span
        class="bi me-2"
        :class="termsHaveBeenAccepted ? 'bi-check-circle' : 'bi-circle'"
      />
      <span>Accetto termini e condizioni</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useLegalStore} from '@/stores/LegalStore';

export default defineComponent({
  name: 'TermsWidget',
  props: {
    isShippingAddress: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['toggleTermsAcceptance'],
  setup() {
    return {
      legalStore: useLegalStore(),
    };
  },
  data() {
    return {
      termsHaveBeenAccepted: false as boolean,
    };
  },
  mounted() {
    this.legalStore.list();
  },
  methods: {
    toggleTermsAcceptance: function() {
      this.termsHaveBeenAccepted = !this.termsHaveBeenAccepted;
      this.$emit('toggleTermsAcceptance');
    },
  },
});
</script>

<style scoped>
.bi{
  font-size: 22px;
}
.bi-check-circle, .bi-circle{
  font-size: 18px;
}

.terms{
  max-height: 200px
}


</style>
