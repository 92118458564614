/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {CategoryInterface, Filter, Pagination, Sorting} from '@/types/entities';
import CategoryData from '@/services/data/CategoryData';

export const useCategoryStore = defineStore('CategoryStore', {
  state: () => {
    return {
      categories: [] as CategoryInterface[],
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      this.categories = await (new CategoryData()).list(pagination, filters, sorting, signal);
    },
    clear() {
      this.categories = [];
    },
  },
});
