<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div
    class="card rounded-4 border-0 mb-4"
  >
    <h5
      class="card-header rounded-bottom rounded-4 text-white p-3 bg-lavanda"
    >
      Riepilogo
    </h5>
    <div class="card-body mx-2 my-4">
      <div class="d-flex justify-content-between mb-2">
        <span>Totale netto</span>
        <span>€ {{ cartStore.cart.netAmount }}</span>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <span>Spedizione</span>
        <span>€ {{ cartStore.cart.shippingCost }}</span>
      </div>
      <div class="d-flex justify-content-between pb-3 border-2 border-bottom border-gray3">
        <span>IVA 22%</span>
        <span>€ {{ cartStore.cart.vatAmount }}</span>
      </div>
      <div class="d-flex justify-content-between text-green5 fw-bold mt-3">
        <span>Totale ordine</span>
        <span>€ {{ cartStore.cart.totalAmount }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useCartStore} from '@/stores/CartStore';

export default defineComponent({
  name: 'SummaryWidget',
  setup() {
    {
      return {
        cartStore: useCartStore(),
      };
    }
  },
});
</script>

<style scoped>
.rounded-bottom{
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
