<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <footer class="bg-gray2 py-3">
    <section
      class="container-fluid d-block overflow-auto text-center"
    >
      Greenkar Innovation s.r.l. - Sede legale: via Riva Ligure, 49 - 00163 RM - P.I. 02671430425 | REA RM 1563567
      - tel:
      <a
        href="tel:+39063328514"
        class="text-decoration-none"
      >
        +39 06.3328514
      </a>
      - e-mail:
      <a
        href="mailto:autocarri@greenkar-sv.it"
        class="text-decoration-none"
      >
        autocarri@greenkar-sv.it
      </a>
    </section>
  </footer>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'MainFooter',
});
</script>

<style scoped>
  footer, footer a {
    font-size: 12px;
    color: #999;
  }
</style>
