/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {Filter, Pagination, ProductInterface, Sorting, VehicleFrameInterface} from '@/types/entities';
import ProductData from '@/services/data/ProductData';

export const useProductStore = defineStore('ProductStore', {
  state: () => {
    return {
      products: [] as ProductInterface[],
      currentProduct: {} as ProductInterface,
      vehicleFrameList: [] as VehicleFrameInterface[],
      activeSorting: {} as Sorting,
      activeFilters: [] as Filter[],
      pagination: {} as Pagination,
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      this.products = await (new ProductData()).list(pagination, filters, sorting, signal);
    },
    async get(productId: number) {
      this.currentProduct = await (new ProductData()).get(productId);
    },
    async getVehicleFramesList(productId: number, pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      this.vehicleFrameList = await (new ProductData()).getVehicleFrameList(productId, pagination, filters, sorting, signal);
    },
    clear() {
      this.products = [];
      this.currentProduct = {} as ProductInterface;
      this.vehicleFrameList = [];
      this.activeSorting = {} as Sorting;
      this.activeFilters = [] as Filter[];
      this.pagination = {} as Pagination;
    },
  },
});
