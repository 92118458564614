<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="bg-gray1 d-inline-flex px-4 py-3 rounded-4 w-100">
    <bootstrap-table
      v-model="tableProps"
    />
    <router-view @order-updated="getDocumentList" />
  </div>
</template>

<script lang="ts">
import {defineComponent, shallowRef} from 'vue';
import {BootstrapTableProps} from '@/types/entities';
import {useDocumentStore} from '@/stores/DocumentStore';
import BootstrapTable from '@/components/BootstrapTable/BootstrapTable.vue';
import documentActions from '@/components/Document/TableData/DocumentActions.vue';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default defineComponent({
  name: 'DocumentList',
  components: {BootstrapTable},
  setup() {
    return {
      documentStore: useDocumentStore(),
    };
  },
  data() : {
    created: boolean,
    abortController: AbortController,
    tableProps: BootstrapTableProps
    } {
    return {
      created: false,
      abortController: new AbortController(),
      tableProps: {
        columns: [
          {
            title: 'Titolo documento',
            field: 'title',
            sortable: true,
          },
          {
            title: 'Nome documento',
            field: 'filename',
            sortable: true,
          },
          {
            title: 'Data caricamento',
            field: 'creationDate',
            fieldAlias: 'formattedCreationDate',
            sortable: false,
            filters: [
              {
                field: 'uploadDate',
                type: 'date',
              },
            ],
          },
          {
            title: 'Numero ordine',
            field: 'orderNumber',
            sortable: false,
            filters: [
              {
                field: 'orderNumber',
                type: 'text',
              },
            ],
          },
          {
            title: 'VIN telaio',
            field: 'vehicleFrameNum',
            sortable: false,
            filters: [
              {
                field: 'vehicleFrameNum',
                type: 'text',
              },
            ],
          },
          {
            title: 'Azioni',
            field: 'actions',
            sortable: false,
            component: shallowRef(documentActions),
          },
        ],
        data: [],
        loading: false,
        sorting: {
          field: 'number',
          direction: 'desc',
        },
        activeFilters: [],
        pagination: {
          page: 1,
          perPage: 10,
        },
      },
    };
  },
  watch: {
    'tableProps.sorting': async function() {
      this.documentStore.activeSorting = this.tableProps.sorting;
      await this.getDocumentList();
    },
    'tableProps.pagination': async function() {
      this.documentStore.pagination = this.tableProps.pagination;
      await this.getDocumentList();
    },
    'tableProps.activeFilters': async function() {
      this.documentStore.activeFilters = this.tableProps.activeFilters;
      if (this.created) this.tableProps.pagination.page = 1;
      await this.getDocumentList();
    },
  },
  created: async function() {
    if (this.documentStore.activeSorting.field) {
      this.tableProps.sorting = this.documentStore.activeSorting;
    }
    if (this.documentStore.pagination.page) {
      this.tableProps.pagination = this.documentStore.pagination;
    }
    if (this.documentStore.activeFilters.length > 0) {
      this.tableProps.activeFilters = this.documentStore.activeFilters;
    }
    await this.getDocumentList();
    this.created = true;
  },
  methods: {
    async getDocumentList() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      try {
        this.tableProps.loading = true;
        await this.documentStore.list(this.tableProps.pagination, this.tableProps.activeFilters, this.tableProps.sorting, this.abortController.signal);
        this.tableProps.data = this.documentStore.documents;
        this.tableProps.loading = false;
      } catch (error) {
        ErrorHandlerService.handle(error);
      }
    },
  },
});
</script>
