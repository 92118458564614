/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {Filter, Pagination, ProductInterface, Sorting, VehicleFrameInterface} from '@/types/entities';
import VehicleFrameData from '@/services/data/VehicleFrameData';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export const useVehicleFrameStore = defineStore('VehicleFrameStore', {
  state: () => {
    return {
      vehicleFrames: [] as VehicleFrameInterface[],
      currentVehicleFrame: {} as VehicleFrameInterface,
      vehicleFrameProductList: [] as ProductInterface[],
      activeSorting: {} as Sorting,
      activeFilters: [] as Filter[],
      pagination: {} as Pagination,
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      this.vehicleFrames = await (new VehicleFrameData()).list(pagination, filters, sorting, signal);
    },
    async get(vehicleFrameId: number) {
      try {
        this.currentVehicleFrame = await (new VehicleFrameData()).get(vehicleFrameId);
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
        throw error;
      }
    },
    async getProductList(vehicleFrameId: number) {
      try {
        this.vehicleFrameProductList = (await (new VehicleFrameData()).getProductList(vehicleFrameId)).products;
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
        throw error;
      }
    },
    clear() {
      this.vehicleFrames = [];
      this.currentVehicleFrame = {} as VehicleFrameInterface;
      this.vehicleFrameProductList = [];
      this.activeSorting = {} as Sorting;
      this.activeFilters = [] as Filter[];
      this.pagination = {} as Pagination;
    },
  },
});
