import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray1 d-inline-flex px-4 py-3 rounded-4 w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bootstrap_table = _resolveComponent("bootstrap-table")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bootstrap_table, {
      modelValue: _ctx.tableProps,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableProps) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_router_view, { onOrderUpdated: _ctx.getDocumentList }, null, 8, ["onOrderUpdated"])
  ]))
}