<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="d-flex">
    <span
      class="bi bi-dot fs-2 d-grid"
      :class="labelColor"
    />
    <span> {{ user.roleLabel }} </span>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import User from '@/types/User';

export default defineComponent({
  name: 'UserRoleLabel',
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  computed: {
    labelColor: function() {
      switch (this.user.role) {
        case 'ROLE_GREENKAR':
          return 'text-earls-green';
        case 'ROLE_CARMAKER':
          return 'text-light-blue';
        case 'ROLE_VENDOR':
          return 'text-lavanda';
        case 'ROLE_DEALER':
          if (this.user.mainUser !== null) {
            return 'text-tacha';
          }
          return 'text-amethyst';
        default:
          return 'text-gray';
      }
    },
  },
});
</script>

<style scoped lang="scss">
.bi.bi-dot {
  height: 0;
  line-height: 0;

  &:before {
    line-height: 0.75;
  }
}
</style>
