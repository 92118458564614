/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {
  AddressInterface, Filter,
  LoginResponseInterface, Pagination,
  RegistrationResponseInterface, Sorting, UpdateAddressInterface, UpdateUserDataInterface, UpdateUserInterface,
  UserInterface, UserOperations,
} from '@/types/entities';

export default class UserData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<UserInterface[]> {
    const url = new URL(`${this.apiUrl}/user`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(url, signal);
  }

  get(userId: number): Promise<UserInterface> {
    const url = new URL(`${this.apiUrl}/user/${userId}`);

    return this.fetchWithAuth(url) as Promise<UserInterface>;
  }

  create(user: UserInterface | UpdateUserInterface): Promise<UserInterface> {
    const url = new URL(`${this.apiUrl}/user`);

    return this.fetchWithAuth(
        url,
        {
          method: 'POST',
          body: JSON.stringify(user),
        },
    ) as Promise<UserInterface>;
  }

  update(userId: number, data: UpdateUserDataInterface | UpdateUserInterface | UserInterface): Promise<{ id: string }> {
    const url = new URL(`${this.apiUrl}/user/${userId}`);

    return this.fetchWithAuth(
        url,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
    ) as Promise<{id: string}>;
  }

  login(email: string, password: string): Promise<LoginResponseInterface> {
    const url = new URL(`${this.apiUrl}/user/login`);

    return fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
        .then(async (response) => {
          await this.validateResponse(response);
          return response.json();
        })
        .then((data) => data.data);
  }

  refresh(refreshToken: string): Promise<LoginResponseInterface> {
    const url = new URL(`${this.apiUrl}/user/token/refresh`);

    return fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    }).then(async (response) => {
      await this.validateResponse(response);
      return response.json();
    }).then((data) => data.data);
  }

  current(): Promise<{ user: UserInterface, operations: UserOperations }> {
    return this.fetchWithAuth(new URL(`${this.apiUrl}/user/current`)) as Promise<{ user: UserInterface, operations: UserOperations }>;
  }

  resetPassword(email: string): Promise<Response> {
    const url = new URL(`${this.apiUrl}/user/${email}/reset-password`);

    return fetch(url.toString(), {
      method: 'POST',
    });
  }

  registration(email: string, password: string, dealerCode: string): Promise<RegistrationResponseInterface> {
    const url = new URL(`${this.apiUrl}/user/registration`);

    return fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify({email: email, password: password, dealerCode: dealerCode}),
    })
        .then(async (response) => {
          await this.validateResponse(response);
          return response.json();
        })
        .then((data) => data.data);
  }

  getAddresses(userId: number, type?: string, main?: boolean): Promise<AddressInterface[]> {
    const url = new URL(`${this.apiUrl}/user/${userId}/address`);
    if (type) {
      url.searchParams.append('type', type);
    }
    if (main) {
      url.searchParams.append('main', String(main));
    }

    return this.fetchWithAuth(url) as Promise<AddressInterface[]>;
  }

  createAddress(userId: number, data: AddressInterface | UpdateAddressInterface): Promise<{ id: string, id_user: string }> {
    const url = new URL(`${this.apiUrl}/user/${userId}/address`);
    return this.fetchWithAuth(
        url,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
    ) as Promise<{id: string, id_user: string}>;
  }

  updateAddress(userId: number, addressId: number, data: UpdateAddressInterface): Promise<{ id: string, id_user: string }> {
    const url = new URL(`${this.apiUrl}/user/${userId}/address/${addressId}`);

    return this.fetchWithAuth(
        url,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
    ) as Promise<{id: string, id_user: string}>;
  }

  deleteAddress(userId: number, addressId: number): Promise<string> {
    const url = new URL(`${this.apiUrl}/user/${userId}/address/${addressId}`);

    return this.fetchWithAuth(
        url,
        {
          method: 'DELETE',
        },
    ) as Promise<string>;
  }
}
