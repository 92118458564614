/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Cron from 'croner';
import {EventEmitter} from 'events';

export default class SessionService extends EventEmitter {
  private job: Cron | undefined;

  setupSession(expirationDate: Date) {
    this.job = new Cron(expirationDate, () => {
      this.emit('jwt-expired');
    });
  }

  clearSession() {
    if (!this.job) {
      return;
    }
    this.job.stop();
  }
}
