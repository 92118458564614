/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {createApp} from 'vue';
import App from '@/App.vue';
import router from '@/router';
import 'bootstrap';
import Toast from 'vue-toastification';
import '@/styles/main.scss';
import Api from '@/services/data/Api';
import {createPinia} from 'pinia';
import UserData from '@/services/data/UserData';
import OrderData from '@/services/data/OrderData';
import DocumentData from '@/services/data/DocumentData';
import CartData from '@/services/data/CartData';
import CategoryData from '@/services/data/CategoryData';
import NewsData from '@/services/data/NewsData';
import ProductData from '@/services/data/ProductData';
import VehicleFrameData from '@/services/data/VehicleFrameData';
import ImportData from '@/services/data/ImportData';
import LegalData from '@/services/data/LegalData';
import CarrierData from '@/services/data/CarrierData';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $apiHelper: Api
    $cartData: CartData
    $categoryData: CategoryData
    $courierData: CarrierData
    $documentData: DocumentData
    $importData: ImportData
    $newsData: NewsData
    $orderData: OrderData
    $legalData: LegalData
    $productData: ProductData
    $userData: UserData
    $vehicleFrameData: VehicleFrameData
  }
}

const instance = createApp(App);
const pinia = createPinia();

instance.use(router);
instance.use(Toast);
instance.use(pinia);
instance.mount('#app');

instance.config.globalProperties.$apiHelper = new Api();
instance.config.globalProperties.$cartData = new CartData();
instance.config.globalProperties.$categoryData = new CategoryData();
instance.config.globalProperties.$courierData = new CarrierData();
instance.config.globalProperties.$documentData = new DocumentData();
instance.config.globalProperties.$importData = new ImportData();
instance.config.globalProperties.$newsData = new NewsData();
instance.config.globalProperties.$orderData = new OrderData();
instance.config.globalProperties.$legalData = new LegalData();
instance.config.globalProperties.$productData = new ProductData();
instance.config.globalProperties.$userData = new UserData();
instance.config.globalProperties.$vehicleFrameData = new VehicleFrameData();
