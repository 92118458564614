/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import NavigationLink from '@/types/NavigationLink';
import router from '@/router';
import {Router} from 'vue-router';
import {useUserStore} from '@/stores/UserStore';
import {getProperty} from 'dot-prop';

export default class NavigationService {
  private userStore = useUserStore();
  private router: Router = router;
  private navigationLinks: NavigationLink[] = [
    new NavigationLink(
        'Dashboard',
        'Dashboard',
        'Dashboard',
        'house',
        'menuItems.dashboard',
    ),
    new NavigationLink(
        'Telai',
        'VehicleFramesList',
        'Telai',
        'car-front',
        'menuItems.vehicleFrame',
    ),
    new NavigationLink(
        'Ordini',
        'OrderList',
        'Ordini',
        'file-earmark-ruled',
        'menuItems.order',
    ),
    new NavigationLink(
        'Documenti',
        'DocumentList',
        'Documenti',
        'file-earmark-text',
        'menuItems.document',
    ),
    new NavigationLink(
        'Prodotti',
        'ProductList',
        'Prodotti',
        'boxes',
        'menuItems.product',
    ),
    new NavigationLink(
        'Utenti',
        'UserList',
        'Utenti',
        'people',
        'menuItems.user',
    ),
    new NavigationLink(
        'Profilo',
        'Profile',
        'Profilo',
        'menuItems.profile',
    ),
    new NavigationLink(
        'Credenziali',
        'Dashboard',
        'Credenziali',
        'shield-check',
        'menuItems.credentials',
    ),
    new NavigationLink(
        'Carrello',
        'Cart',
        'Carrello',
        'menuItems.cart',
    ),
  ];

  public getNavigationLinks(): NavigationLink[] {
    return this.navigationLinks.filter((link) => {
      if (link.userOperation === null) {
        return true;
      }

      if (!Object.keys(this.userStore.userOperations).length) {
        return false;
      }

      return getProperty(this.userStore.userOperations, link.userOperation);
    });
  }

  public getActiveNavigationLink(): NavigationLink|null {
    const currentRouteName = this.router.currentRoute.value.name;
    return this.navigationLinks.find((link) => link.routeName === currentRouteName) || null;
  }
}
