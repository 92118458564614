<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <order-status-label :order="row" />
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Order from '@/types/Order';
import {Column} from '@/types/entities';
import OrderStatusLabel from '@/components/Order/OrderStatusLabel.vue';

export default defineComponent({
  name: 'OrderStatus',
  components: {OrderStatusLabel},
  props: {
    row: {
      type: Object as PropType<Order>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  computed: {
    statusColor() {
      switch (this.row.status) {
        case 'created':
          return 'bg-sun-orange';
        case 'payed':
          return 'bg-amethyst';
        case 'shipped':
          return 'bg-earls-green';
        case 'canceled':
          return 'bg-red1';
        default:
          return 'bg-dark';
      }
    },
  },
});
</script>
