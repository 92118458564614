<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div
    class="d-flex flex-column justify-content-between vh-100 p-3 bg-gray1"
  >
    <section>
      <img
        class="p-5 img-fluid"
        src="@/assets/logo-greenkar.svg"
        alt="Greenkar Logo"
      >
    </section>
    <section>
      <main-nav />
    </section>
    <section>
      <div class="row mb-3 pb-1 ms-4 ps-1">
        <div class="col-2 mt-2">
          <span
            class="bi bi-headset rounded-circle bg-lavanda fs-5 text-white py-2 contact-icon-px"
          />
        </div>
        <div class="col-10 ps-3">
          <div>
            <a
              href="tel:+39063328514"
              class="text-decoration-none"
            >
              <span class="text-lavanda">+39 06 332 85 14</span>
            </a>
          </div>
          <a
            href="mailto:autocarri@greenkar-sv.it"
            class="text-decoration-none"
          >
            <span class="text-gray4 email-size">autocarri@greenkar-sv.it</span>
          </a>
        </div>
      </div>
      <div class="bg-gray2 rounded-5 mb-5 mx-4">
        <main-actions />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import MainNav from '@/components/Root/MainNav.vue';
import MainActions from '@/components/Root/MainActions.vue';

export default defineComponent({
  name: 'MainSidebar',
  components: {MainActions, MainNav},
});
</script>

<style scoped>
.contact-icon-px {
  padding-right: 0.68rem !important;
  padding-left: 0.68rem !important;
}
.email-size {
  font-size: 0.8rem;
}
</style>
