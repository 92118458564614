/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {NewsInterface, NewsUserType} from '@/types/entities';
import {DateTime} from 'luxon';

export default class News implements NewsInterface {
  id: number;
  active: boolean;
  title: string;
  content: string;
  userType: [keyof typeof NewsUserType][];
  carMaker: string[];
  deactivationDate: string;
  creationDate: string;

  constructor(data: NewsInterface) {
    this.id = data.id;
    this.active = data.active;
    this.title = data.title;
    this.content = data.content;
    this.userType = data.userType;
    this.carMaker = data.carMaker;
    this.deactivationDate = data.deactivationDate;
    this.creationDate = data.creationDate;
  }

  get formattedDeactivationDate(): string {
    if (!this.deactivationDate) {
      return '';
    }
    return DateTime.fromISO(this.deactivationDate).toLocaleString(DateTime.DATE_SHORT);
  }

  get formattedCreationDate(): string {
    if (!this.creationDate) {
      return '';
    }
    return DateTime.fromISO(this.creationDate).toLocaleString(DateTime.DATE_SHORT);
  }
}
