import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row ms-5 ps-3" }
const _hoisted_2 = { class: "col-12 d-flex justify-content-center align-items-center mb-5" }
const _hoisted_3 = { class: "w-50" }
const _hoisted_4 = { class: "col-9 row" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "col-12 mt-3" }
const _hoisted_7 = {
  key: 0,
  class: "col-3"
}
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "spinner-border spinner-border-sm"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 1,
  class: "col-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_progress_bar = _resolveComponent("progress-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_terms_widget = _resolveComponent("terms-widget")!
  const _component_SummaryWidget = _resolveComponent("SummaryWidget")!
  const _component_order_completed_widget = _resolveComponent("order-completed-widget")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_progress_bar)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view),
      (_ctx.$route.fullPath.includes('/checkout/address'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_terms_widget, { ref: "termsWidget" }, null, 512)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$route.name !== 'CheckoutOrders')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_SummaryWidget),
          _createElementVNode("button", {
            class: "btn btn-dark w-100 p-3 text-uppercase",
            disabled: _ctx.loading,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToNextStep && _ctx.goToNextStep(...args)))
          }, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, "Continua con l'acquisto"))
          ], 8, _hoisted_8)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_order_completed_widget)
        ]))
  ]))
}