import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "row g-0"
}
const _hoisted_2 = { class: "col-xl-2 col-3 vh-100" }
const _hoisted_3 = { class: "col-xl-10 col-9 vh-100 bg-gray2 d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_main_sidebar = _resolveComponent("main-sidebar")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_main_view = _resolveComponent("main-view")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_ctx.cleanView)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        class: "vh-100"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_main_sidebar)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_main_header),
          _createVNode(_component_main_view, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }),
          _createVNode(_component_main_footer)
        ])
      ]))
}