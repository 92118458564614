import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "ps-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bootstrap_table_data = _resolveComponent("bootstrap-table-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["bi fs-5", [_ctx.row.orderDetails.length > 1 ? 'bi-boxes' : 'bi-box']])
    }, null, 2),
    _createElementVNode("span", _hoisted_2, [
      _createTextVNode(" x "),
      _createVNode(_component_bootstrap_table_data, {
        row: _ctx.row,
        column: _ctx.column
      }, null, 8, ["row", "column"])
    ])
  ]))
}