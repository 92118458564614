/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {
  Filter,
  Pagination,
  ProductInterface,
  Sorting,
  VehicleFrameInterface,
  VehicleFrameUpdateRequestInterface,
} from '@/types/entities';

export default class VehicleFrameData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<VehicleFrameInterface[]> {
    const url = new URL(`${this.apiUrl}/vehicle-frame`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(
        url,
        signal,
    );
  }

  get(vehicleFrameId: number): Promise<VehicleFrameInterface> {
    const url = new URL(`${this.apiUrl}/vehicle-frame/${vehicleFrameId}`);
    return this.fetchWithAuth(url);
  }

  create(data: VehicleFrameInterface): Promise<{id: string, vehicleFrameNum: string}> {
    const url = new URL(`${this.apiUrl}/vehicle-frame`);
    return this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  update(vehicleFrameId: number, data: VehicleFrameUpdateRequestInterface | VehicleFrameInterface): Promise<{id: string, vehicleFrameNum: string}> {
    const url = new URL(`${this.apiUrl}/vehicle-frame/${vehicleFrameId}`);
    return this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  getProductList(vehicleFrameId: number): Promise<{ ordersUsageCount:number, products:ProductInterface[] }> {
    const url = new URL(`${this.apiUrl}/vehicle-frame/${vehicleFrameId}/product`);
    return this.fetchWithAuth(url);
  }

  createProductRelation(vehicleFrameId: string, productId: number): Promise<{id: string}> {
    const url = new URL(`${this.apiUrl}/vehicle-frame/${vehicleFrameId}/product`);
    return this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({productId}),
    });
  }
}
