/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {TermsConditionsInterface,
} from '@/types/entities';

export default class LegalData extends Api {
  list(): Promise<TermsConditionsInterface> {
    const url = new URL(`${this.apiUrl}/legal/terms-conditions`);

    return this.fetchWithAuth(url) as Promise<TermsConditionsInterface>;
  }
}
