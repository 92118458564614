<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <user-role-label :user="row" />
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Column} from '@/types/entities';
import User from '@/types/User';
import UserRoleLabel from '@/components/User/UserRoleLabel.vue';

export default defineComponent({
  name: 'UserStatus',
  components: {UserRoleLabel},
  props: {
    row: {
      type: Object as PropType<User>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
});
</script>
