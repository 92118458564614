<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <span
    class="badge rounded-pill text-nowrap"
    :class="statusColor"
  >
    {{ user.active ? 'Attivo' : 'Non attivo' }}
  </span>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import User from '@/types/User';

export default defineComponent({
  name: 'UserStatusLabel',
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  computed: {
    statusColor() {
      switch (this.user.active) {
        case false:
          return 'bg-sun-orange';
        default:
          return 'bg-earls-green';
      }
    },
  },
});
</script>
