<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <base-widget
    title="DCA ultimo mese"
    icon="file-earmark-text"
    route-name="DocumentList"
    route-icon="arrow-right"
  >
    <template #default>
      <div class="overflow-scroll d-block">
        <ul class="list-group pt-2">
          <li
            v-for="document in documents"
            :key="document.id"
            class="border-top lh-lg border-bottom d-flex justify-content-between"
          >
            <div>
              <span
                class="me-2 bi"
                :class="`bi-filetype-${document.extension}`"
              />
              <span>
                {{ document.filename }}
              </span>
            </div>
            <div v-if="userStore.userOperations.dashboard?.dcaWidget.download">
              <button
                class="btn btn-sm btn-link text-secondary"
                @click="downloadDocument(document)"
              >
                <span class="bi bi-download" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </base-widget>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseWidget from '@/components/Dashboard/BaseWidget.vue';
import {useDocumentStore} from '@/stores/DocumentStore';
import {DocumentInterface} from '@/types/entities';
import DocumentDownloader from '@/services/DocumentDownloader';
import {useUserStore} from '@/stores/UserStore';
import ErrorHandlerService from '@/services/ErrorHandlerService';
import {DateTime} from 'luxon';

export default defineComponent({
  name: 'DcaWidget',
  components: {BaseWidget},
  setup: () => {
    return {
      documentStore: useDocumentStore(),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      loading: false,
      documentDownloader: new DocumentDownloader(),
    };
  },
  computed: {
    documents() {
      return this.documentStore.documents.map((document) => {
        return {
          ...document,
          extension: document.filename.split('.').pop(),
        };
      });
    },
  },
  async created() {
    await this.documentStore.list(undefined, [
      {
        field: 'uploadDateFrom',
        value: DateTime.now().minus({month: 1}).toSQLDate(),
      },
      {
        field: 'uploadDateTo',
        value: DateTime.now().toSQLDate(),
      },
      {
        field: 'type',
        value: 'dca',
      },
    ],
    {
      field: 'creationDate',
      direction: 'desc',
    });
  },
  methods: {
    async downloadDocument(document: DocumentInterface) {
      try {
        this.loading = true;
        await this.documentDownloader.download(document);
      } catch (error) {
        ErrorHandlerService.handle(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.overflow-scroll {
  height: 90%;
}
</style>
