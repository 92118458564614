<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <ul class="nav nav-pills flex-column px-2">
    <li
      v-for="link in links"
      :key="link.name"
      class="nav-item p-2"
    >
      <router-link
        class="nav-link rounded-4 py-3 mx-2"
        :class="[ isLinkActive(link) ? 'active' : 'link-dark' ]"
        :to="{'name': link.routeName}"
      >
        <span
          class="bi px-2"
          :class="[`bi-${link.icon}`]"
        />
        {{ link.name }}
      </router-link>
    </li>
    <li
      v-if="userStore.currentUser.role === 'ROLE_DEALER'"
      class="nav-item p-2"
    >
      <a
        class="nav-link rounded-4 py-3 mx-2 link-dark pdf-link"
        href="/assets/manuale_uso.pdf"
        target="_blank"
      >
        <span
          class="bi px-2 bi bi-file-earmark-arrow-down"
        />
        Manuale d'uso
      </a>
    </li>
    <li
      v-if="userStore.currentUser.role === 'ROLE_DEALER'"
      class="nav-item p-2"
    >
      <a
        class="nav-link rounded-4 py-3 mx-0 link-dark pdf-link"
        href="/assets/manuale_uso_ collaudi_km0.pdf"
        target="_blank"
      >
        <span class="row">
          <span class="col-2 pt-1 mt-1">
            <span
              class="bi px-2 bi bi-file-earmark-arrow-down col-3 ms-2"
            />
          </span>
          <span class="col-9">
            Manuale D'uso Collaudo a Km 0
          </span>
        </span>
      </a>
    </li>
  </ul>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import NavigationService from '@/services/NavigationService';
import NavigationLink from '@/types/NavigationLink';
import {useUserStore} from '@/stores/UserStore';

export default defineComponent({
  name: 'MainNav',
  setup: () => {
    return {
      navigationService: new NavigationService(),
      navigationLinksToDisplay: [
        'Dashboard',
        'Telai',
        'Ordini',
        'Documenti',
        'Prodotti',
        'Utenti',
      ],
      userStore: useUserStore(),
    };
  },
  computed: {
    links: function() {
      return this.navigationService.getNavigationLinks()
          .filter((link: NavigationLink) => this.navigationLinksToDisplay.includes(link.name));
    },
  },
  methods: {
    isLinkActive: function(link: NavigationLink): boolean {
      if (this.$route.name === link.routeName) {
        return true;
      }
      const DetailPageName = link.routeName.replace('List', 'Detail');
      if (this.$route.name === DetailPageName) {
        return true;
      }
      return false;
    },
  },
});
</script>
