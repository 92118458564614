<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <base-widget
    title="News"
    icon="bell"
    :route-name="userStore.userOperations.dashboard?.newsWidget.addPopup ? 'NewsAdd' : null"
    route-icon="plus-lg"
  >
    <template #default>
      <div class="overflow-scroll">
        <article
          v-for="news in newsStore.news"
          :key="news.id"
          class="mt-3 rounded-4 p-3"
          :class="[news.active ? 'bg-gray3' : 'disabled']"
          @click="$router.push({ name: 'NewsDetail', params: { newsId: news.id } })"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span class="fw-bold title">
              {{ news.title }}
            </span>
            <small class="text-secondary">
              {{ news.formattedCreationDate }}
            </small>
          </div>
          <p class="mb-0 mt-2">
            {{ news.content }}
          </p>
        </article>
      </div>
      <router-view @news-updated="getList" />
    </template>
  </base-widget>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseWidget from '@/components/Dashboard/BaseWidget.vue';
import {useNewsStore} from '@/stores/NewsStore';
import {useUserStore} from '@/stores/UserStore';

export default defineComponent({
  name: 'NewsWidget',
  components: {BaseWidget},
  setup() {
    return {
      userStore: useUserStore(),
      newsStore: useNewsStore(),
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    async getList() {
      await this.newsStore.list(
          {
            page: 1,
            perPage: 30,
          },
          undefined,
          {
            field: 'creationDate',
            direction: 'desc',
          },
      );
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

.overflow-scroll {
  height: 90%;
}

article {
  font-size: small;
  cursor: pointer;
  &:hover:not(.disabled) {
    background-color: map-get($custom-colors, 'gray4')!important;
  }

  &:not(.disabled) .title {
    color: map-get($custom-colors, 'green3')!important;
  }

  &.disabled{
    color: map-get($custom-colors, 'gray4');
    background: linear-gradient(135deg, #ededef 25%, transparent 25%, transparent 50%, #ededef 50%, #ededef 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
}
</style>
