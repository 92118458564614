<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div
    :class="[paymentFailed ? 'bg-danger' : 'bg-success']"
    class="rounded-4 p-4 h-100 position-relative text-light"
  >
    <div v-if="paymentFailed">
      <span class="bi bi-exclamation-circle me-2" />
      Pagamento non riuscito
      <button
        v-if="order"
        class="btn btn-light text-danger ms-2"
        :disabled="loading"
        @click="retryPayment"
      >
        Ritenta il pagamento
        <span
          v-if="loading"
          class="spinner-border spinner-border-sm ms-2"
        />
      </button>
    </div>
    <div v-else>
      <span class="bi bi-check-circle me-2" />
      Pagamento riuscito
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Order from '@/types/Order';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default defineComponent({
  name: 'OrderPaymentStatus',
  props: {
    order: {
      type: Object as PropType<Order|null>,
      required: false,
      default: null,
    },
    paymentFailed: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  data() {
    return {
      loading: false as boolean,
    };
  },
  methods: {
    async retryPayment() {
      if (!this.order) {
        return;
      }
      this.loading = true;
      try {
        const paypalLink = await this.$orderData.payWithPaypal([this.order.id]);
        window.location.href = paypalLink.url;
      } catch (error) {
        this.loading = false;
        ErrorHandlerService.handle(error);
      }
    },
  },
});
</script>
