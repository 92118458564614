<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <span>{{ getProperty(row, column.fieldAlias ? column.fieldAlias : column.field) }}</span>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Column} from '@/types/entities';
import {getProperty} from 'dot-prop';

export default defineComponent({
  name: 'BootstrapTableData',
  props: {
    row: {
      type: Object as PropType<any>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  methods: {
    getProperty(row: any, field: string) {
      return getProperty(row, field);
    },
  },
});

</script>
