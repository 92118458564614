import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cf80a66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overflow-scroll" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_4 = { class: "fw-bold title" }
const _hoisted_5 = { class: "text-secondary" }
const _hoisted_6 = { class: "mb-0 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_base_widget = _resolveComponent("base-widget")!

  return (_openBlock(), _createBlock(_component_base_widget, {
    title: "News",
    icon: "bell",
    "route-name": _ctx.userStore.userOperations.dashboard?.newsWidget.addPopup ? 'NewsAdd' : null,
    "route-icon": "plus-lg"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsStore.news, (news) => {
          return (_openBlock(), _createElementBlock("article", {
            key: news.id,
            class: _normalizeClass(["mt-3 rounded-4 p-3", [news.active ? 'bg-gray3' : 'disabled']]),
            onClick: ($event: any) => (_ctx.$router.push({ name: 'NewsDetail', params: { newsId: news.id } }))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(news.title), 1),
              _createElementVNode("small", _hoisted_5, _toDisplayString(news.formattedCreationDate), 1)
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(news.content), 1)
          ], 10, _hoisted_2))
        }), 128))
      ]),
      _createVNode(_component_router_view, { onNewsUpdated: _ctx.getList }, null, 8, ["onNewsUpdated"])
    ]),
    _: 1
  }, 8, ["route-name"]))
}