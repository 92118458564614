<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="bg-white rounded-4 p-4">
    <div class="d-flex justify-content-between align-items-center">
      <h6 class="fw-bold">
        <span
          class="bi text-primary"
          :class="[`bi-${icon}`]"
        />
        {{ title }}
      </h6>
      <router-link
        v-if="routeName"
        class="btn btn-sm btn-gray2 rounded-pill px-2"
        :to="{name: routeName}"
      >
        <span
          class="bi"
          :class="[`bi-${routeIcon}`]"
        />
      </router-link>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

export default defineComponent({
  name: 'BaseWidget',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    routeName: {
      type: String as PropType<string|null>,
      required: false,
      default: null,
    },
    routeIcon: {
      type: String as PropType<string|null>,
      required: false,
      default: null,
    },
  },
});
</script>
