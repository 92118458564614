<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="d-flex justify-content-end mb-3">
    <router-link
      v-if="userStore.userOperations.vehicleFrame?.listView.uploadFileQstockButtonPopup"
      class="btn btn-lavanda text-white me-2"
      :to="{ name: 'VehicleFrameImport' }"
    >
      <span class="bi bi-upload me-2" />
      Importa telai
    </router-link>
    <router-link
      v-if="userStore.userOperations.vehicleFrame?.listView.historyImportsButtonPopup"
      :to="{name: 'VehicleFrameImportHistory'}"
      class="btn btn-gray3"
    >
      storico importazioni
    </router-link>
  </div>

  <div class="bg-gray1 d-inline-flex px-4 py-3 rounded-4 w-100">
    <bootstrap-table
      v-model="tableProps"
    />
    <router-view />
  </div>
</template>

<script lang="ts">
import {defineComponent, shallowRef} from 'vue';
import {BootstrapTableProps} from '@/types/entities';
import {useVehicleFrameStore} from '@/stores/VehicleFrameStore';
import BootstrapTable from '@/components/BootstrapTable/BootstrapTable.vue';
import VehicleFrameActions from '@/components/VehicleFrames/TableData/VehicleFrameActions.vue';
import {useUserStore} from '@/stores/UserStore';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default defineComponent({
  name: 'VehicleFramesList',
  components: {BootstrapTable},
  setup() {
    return {
      vehicleFrameStore: useVehicleFrameStore(),
      userStore: useUserStore(),
    };
  },
  data(): {
    created: boolean,
    abortController: AbortController,
    tableProps: BootstrapTableProps,
    } {
    return {
      created: false,
      abortController: new AbortController(),
      tableProps: {
        columns: [
          {
            title: 'VIN',
            field: 'vehicleFrameNum',
            sortable: true,
            filters: [
              {
                field: 'vehicleFrameNum',
                type: 'text',
              },
            ],
          },
          {
            title: 'Codice Dealer',
            field: 'dealer.dealerCode',
            sortable: false,
            filters: [
              {
                field: 'dealerCode',
                type: 'text',
              },
            ],
          },
          {
            title: 'Denominazione Dealer',
            field: 'dealer.businessName',
            sortable: false,
            filters: [
              {
                field: 'dealerBusinessName',
                type: 'text',
              },
            ],
          },
          {
            title: 'Descrizione commerciale',
            field: 'commercialDescription',
            sortable: false,
            filters: [
              {
                field: 'commercialDescription',
                type: 'text',
              },
            ],
          },
          {
            title: 'Modello',
            field: 'model',
            sortable: true,
            filters: [
              {
                field: 'model',
                type: 'text',
              },
            ],
          },
          {
            title: 'Azioni',
            field: 'actions',
            sortable: false,
            component: shallowRef(VehicleFrameActions),
          },
        ],
        data: [],
        loading: false,
        sorting: {
          field: 'number',
          direction: 'desc',
        },
        activeFilters: [],
        pagination: {
          page: 1,
          perPage: 10,
        },
      },
    };
  },
  watch: {
    'tableProps.sorting': async function() {
      this.vehicleFrameStore.activeSorting = this.tableProps.sorting;
      await this.getVehicleFramesList();
    },
    'tableProps.pagination': async function() {
      this.vehicleFrameStore.pagination = this.tableProps.pagination;
      await this.getVehicleFramesList();
    },
    'tableProps.activeFilters': async function() {
      this.vehicleFrameStore.activeFilters = this.tableProps.activeFilters;
      if (this.created) this.tableProps.pagination.page = 1;
      await this.getVehicleFramesList();
    },
  },
  async created() {
    if (this.vehicleFrameStore.activeSorting.field) {
      this.tableProps.sorting = this.vehicleFrameStore.activeSorting;
    }
    if (this.vehicleFrameStore.pagination.page) {
      this.tableProps.pagination = this.vehicleFrameStore.pagination;
    }
    if (this.vehicleFrameStore.activeFilters.length > 0) {
      this.tableProps.activeFilters = this.vehicleFrameStore.activeFilters;
    }
    await this.getVehicleFramesList();
    this.created = true;
  },
  methods: {
    getVehicleFramesList: async function() {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      try {
        this.tableProps.loading = true;
        await this.vehicleFrameStore.list(this.tableProps.pagination, this.tableProps.activeFilters, this.tableProps.sorting, this.abortController.signal);
        this.tableProps.data = this.vehicleFrameStore.vehicleFrames;
        this.tableProps.loading = false;
      } catch (error) {
        ErrorHandlerService.handle(error);
      }
    },
  },
});
</script>
