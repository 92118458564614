<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <thead>
    <tr>
      <th
        v-for="column in columns"
        :key="column.field"
        scope="col"
        :class="[hasFilters ? 'border-bottom-0' : 'pb-2']"
      >
        <button
          class="btn btn-sm btn-link text-decoration-none fw-semibold text-dark text-nowrap"
          :disabled="!column.sortable"
          @click="sort(column)"
        >
          {{ column.title }}
          <span
            v-if="column.field === sorting.field"
            class="bi"
            :class="[sorting.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill']"
          />
        </button>
      </th>
    </tr>
    <tr v-if="hasFilters">
      <th
        v-for="column in columns"
        :key="column.field"
        scope="col"
        class="pb-2"
      >
        <div>
          <template
            v-for="filter in column.filters"
            :key="filter.field"
          >
            <input
              v-if="filter.type === 'text'"
              v-model="activeFilters[filter.field]"
              type="text"
              class="form-control form-control-sm bg-gray2 rounded-4"
            >
            <select
              v-if="filter.type === 'select'"
              v-model="activeFilters[filter.field]"
              class="form-select form-control form-select-sm bg-gray2 rounded-4"
            >
              <option :value="null">
                -- Nessuno --
              </option>
              <option
                v-for="option in filter.options"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
            <datepicker
              v-if="filter.type === 'date'"
              v-model="dateFilters[filter.field]"
              locale="it-IT"
              :range="true"
              :format="'dd/MM/yy'"
              :enable-time-picker="false"
              input-class-name="form-control form-control-sm bg-gray2 rounded-4"
              :hide-input-icon="true"
              select-text="Seleziona"
              cancel-text="Annulla"
            />
          </template>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {BootstrapTableProps, Column} from '@/types/entities';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {DateTime} from 'luxon';

export default defineComponent({
  name: 'BootstrapTableHead',
  components: {Datepicker},
  props: {
    modelValue: {
      type: Object as PropType<BootstrapTableProps>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() : { activeFilters: Record<string, string | null>, dateFilters: Record<string, Date[] | null> } {
    return {
      activeFilters: {},
      dateFilters: {},
    };
  },
  computed: {
    hasFilters() : boolean {
      return this.modelValue.columns.some((column: Column) => column.filters && column.filters.length > 0);
    },
    columns: {
      get() {
        return this.modelValue.columns;
      },
      set(value: Column[]) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          columns: value,
        });
      },
    },
    sorting: {
      get() {
        return this.modelValue.sorting;
      },
      set(value: { field: string; direction: 'asc' | 'desc' }) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          sorting: value,
        });
      },
    },
  },
  watch: {
    activeFilters: {
      handler() {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          activeFilters: this.formatFilters(),
        });
      },
      deep: true,
    },
    dateFilters: {
      handler() {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          activeFilters: this.formatFilters(),
        });
      },
      deep: true,
    },
  },
  created: async function() {
    // To retrieve and format already active filters
    const dateFilters: string[] = [];
    this.modelValue.columns.forEach((column) => {
      if (column.filters != undefined) {
        column.filters.forEach((filter) => {
          if (filter.type === 'date') {
            dateFilters.push(filter.field);
          }
        });
      }
    });
    const formattedDateFilters: Record<string, Date[]> = {};
    this.modelValue.activeFilters.forEach((filter) => {
      // Format NOT date filters
      if (!dateFilters.includes(filter.field.replace('From', '')) && !dateFilters.includes(filter.field.replace('To', ''))) {
        this.activeFilters[filter.field] = filter.value;
        return;
      }

      // Format date filters
      if (filter.field.search('From') > 0) {
        const key: string = filter.field.replace('From', '');
        if (formattedDateFilters[key] && formattedDateFilters[key].length > 0) {
          formattedDateFilters[key][0] = new Date(filter.value);
        } else {
          formattedDateFilters[key] = [new Date(filter.value)];
        }
      } else if (filter.field.search('To') > 0) {
        const key = filter.field.replace('To', '');
        if (formattedDateFilters[key] && formattedDateFilters[key].length > 0) {
          formattedDateFilters[key].push(new Date(filter.value));
        } else {
          formattedDateFilters[key] = [new Date(filter.value), new Date(filter.value)];
        }
      }
    });
    this.dateFilters = formattedDateFilters;
  },
  methods: {
    sort(column: Column) {
      if (!column.sortable || !column.field) {
        return;
      }
      if (this.sorting.field === column.field) {
        this.sorting = {
          field: column.field,
          direction: this.sorting.direction === 'asc' ? 'desc' : 'asc',
        };
      } else {
        this.sorting = {
          field: column.field,
          direction: 'asc',
        };
      }
    },
    formatFilters: function() {
      const textFilters = Object.entries(this.activeFilters)
          .filter(([, value]) => value !== null)
          .map(([field, value]) => ({field, value: value as string}));

      const dateFilters = Object.entries(this.dateFilters)
          .filter(([, values]) => values !== null)
          .map(([field, values]) => ({field, value: values as Date[]})).map(({field, value}) => {
            return [
              {field: `${field}From`, value: DateTime.fromJSDate(value[0]).toFormat('yyyy-MM-dd')},
              {field: `${field}To`, value: DateTime.fromJSDate(value[1]).toFormat('yyyy-MM-dd')},
            ];
          }).flat();

      return [
        ...textFilters,
        ...dateFilters,
      ];
    },
  },
});

</script>

<style lang="scss" scoped>
  //tr:first-child th {
  //  border-bottom: none;
  //}

  //tr:last-child th {
  //  padding-bottom: 1.5rem;
  //}

  th button:disabled {
    color: inherit;
    opacity: unset;
  }
</style>
