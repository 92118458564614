import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bootstrap_table_data = _resolveComponent("bootstrap-table-data")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.userStore.userOperations.order?.listView.detailView)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: {name: 'OrderDetail', params: {orderId: _ctx.row.id}},
        class: "text-decoration-none fw-semibold text-dark"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" #"),
          _createVNode(_component_bootstrap_table_data, {
            row: _ctx.row,
            column: _ctx.column
          }, null, 8, ["row", "column"])
        ]),
        _: 1
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createTextVNode(" #"),
        _createVNode(_component_bootstrap_table_data, {
          row: _ctx.row,
          column: _ctx.column
        }, null, 8, ["row", "column"])
      ]))
}