/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {useToast} from 'vue-toastification';

export default class ErrorHandlerService {
  static handle(error: any) {
    if (error.name === 'AbortError') {
      return;
    }
    if (error instanceof Error) {
      (useToast()).error(error.message);
    } else {
      (useToast()).error('Errore sconosciuto');
    }
  }
}
