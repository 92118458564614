import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row h-50" }
const _hoisted_2 = { class: "col-4 h-100" }
const _hoisted_3 = { class: "col-4 h-100" }
const _hoisted_4 = { class: "col-4 h-100" }
const _hoisted_5 = { class: "row h-50 pt-4" }
const _hoisted_6 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_widget = _resolveComponent("news-widget")!
  const _component_dca_widget = _resolveComponent("dca-widget")!
  const _component_orders_widget = _resolveComponent("orders-widget")!
  const _component_statistics_widget = _resolveComponent("statistics-widget")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.userStore.userOperations.dashboard?.newsWidget.view)
          ? (_openBlock(), _createBlock(_component_news_widget, {
              key: 0,
              class: "h-100"
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.userStore.userOperations.dashboard?.dcaWidget.view)
          ? (_openBlock(), _createBlock(_component_dca_widget, {
              key: 0,
              class: "h-100"
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.userStore.userOperations.dashboard?.orderWidget.view)
          ? (_openBlock(), _createBlock(_component_orders_widget, {
              key: 0,
              class: "h-100"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.userStore.userOperations.dashboard?.statsWidget.orderCount || _ctx.userStore.userOperations.dashboard?.statsWidget.averageProcessing)
          ? (_openBlock(), _createBlock(_component_statistics_widget, {
              key: 0,
              class: "h-100"
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}