<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="text-end">
    <button
      v-if="userStore.userOperations.document?.listView.download"
      class="btn btn-sm btn-link text-dark"
      @click="downloadDocument"
    >
      <span
        v-if="!loading"
        class="bi bi-download"
      />
      <span
        v-else
        class="spinner-border spinner-border-sm"
      />
    </button>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Column, DocumentInterface} from '@/types/entities';
import {useUserStore} from '@/stores/UserStore';
import DocumentDownloader from '@/services/DocumentDownloader';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default defineComponent({
  name: 'DocumentActions',
  props: {
    row: {
      type: Object as PropType<DocumentInterface>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      loading: false,
      documentDownloader: new DocumentDownloader(),
    };
  },
  methods: {
    async downloadDocument() {
      try {
        this.loading = true;
        await this.documentDownloader.download(this.row);
      } catch (error) {
        ErrorHandlerService.handle(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
