/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {DocumentInterface, Filter, Pagination, Sorting} from '@/types/entities';
import Document from '@/types/Document';
import DocumentData from '@/services/data/DocumentData';

export const useDocumentStore = defineStore('DocumentStore', {
  state: () => {
    return {
      documents: [] as DocumentInterface[],
      currentDocument: {} as DocumentInterface,
      activeSorting: {} as Sorting,
      activeFilters: [] as Filter[],
      pagination: {} as Pagination,
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      const documents = await (new DocumentData()).list(pagination, filters, sorting, signal);
      this.documents = documents.map((document: DocumentInterface) => new Document(document));
    },
    clear() {
      this.documents = [];
      this.currentDocument = {} as DocumentInterface;
      this.activeSorting = {} as Sorting;
      this.activeFilters = [] as Filter[];
      this.pagination = {} as Pagination;
    },
  },
});
