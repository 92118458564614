<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <base-widget
    title="Statistiche"
    icon="bar-chart"
    :class="(userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus) ? 'mb-5' : ''"
  >
    <template #default>
      <div
        :class="(userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus) ? 'double-line-chart-container' : 'single-line-chart-container'"
        class="d-flex"
      >
        <div
          v-if="userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus"
          class="w-75"
        >
          <line-chart
            css-classes="chart-container"
            :labels="countPerStatusLineChartLabels"
            :datasets="countPerStatusLineChartDataset"
          />
        </div>
        <div
          v-else-if="userStore.userOperations.dashboard?.statsWidget.averageProcessing"
          class="w-75"
        >
          <line-chart
            ref="lineChart"
            css-classes="chart-container"
            :labels="averageProcessingLineChartLabels"
            :datasets="averageProcessingLineChartDataset"
          />
        </div>
        <div class="w-25">
          <pie-chart
            v-if="userStore.userOperations.dashboard?.statsWidget.orderCount"
            ref="pieChart"
            chart-id="pie-chart"
            css-classes="chart-container"
            :labels="pieChartLabels"
            :datasets="pieChartDataset"
          />
        </div>
      </div>
      <div
        v-if="userStore.userOperations.dashboard?.statsWidget.averageProcessing && userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus"
        class="double-line-chart-container w-75 mt-5"
      >
        <line-chart
          ref="lineChart"
          css-classes="chart-container"
          :labels="averageProcessingLineChartLabels"
          :datasets="averageProcessingLineChartDataset"
        />
      </div>
    </template>
  </base-widget>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseWidget from '@/components/Dashboard/BaseWidget.vue';
import LineChart from '@/components/Dashboard/Statistics/LineChart.vue';
import PieChart from '@/components/Dashboard/Statistics/PieChart.vue';
import {OrderStatsInterface, OrderStatus} from '@/types/entities';
import {useUserStore} from '@/stores/UserStore';

export default defineComponent({
  name: 'StatisticsWidget',
  components: {PieChart, LineChart, BaseWidget},
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
  data: () => {
    return {
      stats: {} as OrderStatsInterface,
    };
  },
  computed: {
    averageProcessingLineChartLabels() {
      if (!this.stats || !this.stats.averageProcessing) {
        return [];
      }
      return Object.keys(this.stats.averageProcessing);
    },
    countPerStatusLineChartLabels() {
      if (!this.stats || !this.stats.orderCountPerStatus) {
        return [];
      }
      const carMakersStatsKeys = Object.keys(this.stats.orderCountPerStatus);
      return Object.keys(this.stats.orderCountPerStatus[carMakersStatsKeys[0]]);
    },
    averageProcessingLineChartDataset() {
      if (!this.stats || !this.stats.averageProcessing) {
        return [];
      }
      return [
        {
          label: 'Tempo medio di elaborazione (giorni)',
          data: Object.values(this.stats.averageProcessing),
          borderColor: '#A4DED6',
          fill: false,
        },
      ];
    },
    countPerStatusLineChartDataset() {
      if (!this.stats || !this.stats.orderCountPerStatus) {
        return [];
      }
      const dataset = [] as any;
      const colors = [
        '#A3BDFC',
        '#FFCE80',
        '#CAB6E2',
        '#E1E8B0',
        '#FF9999',
        '#C8CCD0',
      ];
      let colorIndex = 0;
      Object.keys(this.stats.orderCountPerStatus).forEach((key) => {
        let color = '';
        if (colors[colorIndex]) {
          color = colors[colorIndex];
          colorIndex++;
        } else {
          // eslint-disable-next-line security-node/detect-insecure-randomness
          color = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
        }
        dataset.push({
          label: key,
          data: Object.values(this.stats.orderCountPerStatus[key]),
          borderColor: color,
          fill: false,
        });
      });
      return dataset;
    },
    pieChartLabels() {
      if (!this.stats || !this.stats.orderCount) {
        return [];
      }
      return Object.values(OrderStatus);
    },
    pieChartDataset() {
      if (!this.stats || !this.stats.orderCount) {
        return [];
      }
      return [
        {
          data: Object.keys(OrderStatus).map((key) => {
            return this.stats.orderCount[key as keyof typeof OrderStatus];
          }),
          backgroundColor: [
            '#FFA512', // sun-orange
            '#946DC5', // amethyst
            '#B6C83F', // earls-green
            '#FF0000', // red1
            '#6C757D', // grey
          ],
        },
      ];
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.stats = await this.$orderData.stats();
    },
  },
});
</script>

<style scoped lang="scss">
.single-line-chart-container {
  height: 85%;
}
.double-line-chart-container {
  height: 40.8%;
}
</style>
