/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {ImportResultInterface} from '@/types/entities';

export default class ImportData extends Api {
  uploadQstock(file: File ): Promise<{id:string, filename:string}> {
    const url = new URL(`${this.apiUrl}/import/qstock`);

    const formData = new FormData();

    formData.append('content', file);

    return this.fetchWithAuth(
        url,
        {
          method: 'POST',
          body: formData,
        },
    );
  }

  uploadProducts(file: File ): Promise<{id:string, filename:string}> {
    const url = new URL(`${this.apiUrl}/import/product`);

    const formData = new FormData();

    formData.append('content', file);

    return this.fetchWithAuth(
        url,
        {
          method: 'POST',
          body: formData,
        },
    );
  }

  listProduct(): Promise<ImportResultInterface[]> {
    const url = new URL(`${this.apiUrl}/import/product`);
    url.searchParams.append('sort', '-id');
    return this.fetchWithAuth(url);
  }

  listQstock(): Promise<ImportResultInterface[]> {
    const url = new URL(`${this.apiUrl}/import/qstock`);
    url.searchParams.append('sort', '-id');
    return this.fetchWithAuth(url);
  }
}
