/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {Filter, Pagination, ProductInterface, Sorting} from '@/types/entities';

export default class CategoryData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<ProductInterface[]> {
    const url = new URL(`${this.apiUrl}/category`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(url, signal);
  }

  create(data: ProductInterface): Promise<{id:string}> {
    const url = new URL(`${this.apiUrl}/category`);
    return this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  update(categoryId:number, data: ProductInterface): Promise<{id:string}> {
    const url = new URL(`${this.apiUrl}/category/${categoryId}`);
    return this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  delete(categoryId:number): Promise<string> {
    const url = new URL(`${this.apiUrl}/category/${categoryId}`);
    return this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}
