import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08f7a411"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "w-75"
}
const _hoisted_2 = {
  key: 1,
  class: "w-75"
}
const _hoisted_3 = { class: "w-25" }
const _hoisted_4 = {
  key: 0,
  class: "double-line-chart-container w-75 mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_pie_chart = _resolveComponent("pie-chart")!
  const _component_base_widget = _resolveComponent("base-widget")!

  return (_openBlock(), _createBlock(_component_base_widget, {
    title: "Statistiche",
    icon: "bar-chart",
    class: _normalizeClass((_ctx.userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus) ? 'mb-5' : '')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([(_ctx.userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus) ? 'double-line-chart-container' : 'single-line-chart-container', "d-flex"])
      }, [
        (_ctx.userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_line_chart, {
                "css-classes": "chart-container",
                labels: _ctx.countPerStatusLineChartLabels,
                datasets: _ctx.countPerStatusLineChartDataset
              }, null, 8, ["labels", "datasets"])
            ]))
          : (_ctx.userStore.userOperations.dashboard?.statsWidget.averageProcessing)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_line_chart, {
                  ref: "lineChart",
                  "css-classes": "chart-container",
                  labels: _ctx.averageProcessingLineChartLabels,
                  datasets: _ctx.averageProcessingLineChartDataset
                }, null, 8, ["labels", "datasets"])
              ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.userStore.userOperations.dashboard?.statsWidget.orderCount)
            ? (_openBlock(), _createBlock(_component_pie_chart, {
                key: 0,
                ref: "pieChart",
                "chart-id": "pie-chart",
                "css-classes": "chart-container",
                labels: _ctx.pieChartLabels,
                datasets: _ctx.pieChartDataset
              }, null, 8, ["labels", "datasets"]))
            : _createCommentVNode("", true)
        ])
      ], 2),
      (_ctx.userStore.userOperations.dashboard?.statsWidget.averageProcessing && _ctx.userStore.userOperations.dashboard?.statsWidget.orderCountPerStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_line_chart, {
              ref: "lineChart",
              "css-classes": "chart-container",
              labels: _ctx.averageProcessingLineChartLabels,
              datasets: _ctx.averageProcessingLineChartDataset
            }, null, 8, ["labels", "datasets"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class"]))
}