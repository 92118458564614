<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex flex-column align-items-center">
      <div
        class="fs-4 py-2 px-3 bg-green4 rounded-circle bi bi-cart text-black"
      />
      <div class="mt-2">
        Carrello
      </div>
    </div>
    <div
      class="line"
      :class="$route.name !== 'CheckoutCart' ? 'bg-green4' : 'bg-gray4'"
    />
    <div class="d-flex flex-column align-items-center">
      <div
        class="fs-4 py-2 px-3 rounded-circle bi bi-file-earmark-text"
        :class="$route.name !== 'CheckoutCart' ? 'text-black bg-green4' : 'text-white bg-gray4'"
      />
      <div class="mt-2 text-nowrap">
        Riepilogo dati
      </div>
    </div>
    <div
      class="line"
      :class="($route.name === 'CheckoutPayment') || ($route.name === 'CheckoutOrders') ? 'bg-green4' : 'bg-gray4'"
    />
    <div class="d-flex flex-column align-items-center">
      <div
        class="fs-4 py-2 px-3 rounded-circle bi bi-credit-card-2-back"
        :class="($route.name === 'CheckoutPayment') || ($route.name === 'CheckoutOrders') ? 'text-black bg-green4' : 'text-white bg-gray4'"
      />
      <div class="mt-2">
        Pagamento
      </div>
    </div>
    <div
      class="line"
      :class="$route.name === 'CheckoutOrders' ? 'bg-green4' : 'bg-gray4'"
    />
    <div class="d-flex flex-column align-items-center">
      <div
        class="fs-4 py-2 px-3 rounded-circle bi bi-send-check"
        :class="$route.name === 'CheckoutOrders' ? 'text-black bg-green4' : 'text-white bg-gray4'"
      />
      <div class="mt-2">
        Inoltrato
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ProgressBar',
});
</script>

<style scoped>
.bi {
  z-index: 1;
}

.line {
  height: 0.1rem;
  margin-top: -1.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  z-index: 0;
  width: 50%;
}
</style>
