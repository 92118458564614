/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {useSessionStore} from '@/stores/SessionStore';
import {Filter, Pagination, Sorting} from '@/types/entities';

export default class Api {
  protected apiUrl = process.env.VUE_APP_API_URL;
  protected sessionStore = useSessionStore();

  protected fetchList(
      url: URL,
      signal?: AbortSignal,
  ) {
    return this.fetchWithAuth(url, {method: 'GET'}, true, signal);
  }

  protected async fetchWithAuth(
      url: URL,
      options: RequestInit = {method: 'GET'},
      decode = true,
      signal?: AbortSignal,
  ): Promise<Response|any> {
    if (!this.sessionStore.jwt) {
      throw new Error('No JWT token');
    }
    const token = this.sessionStore.jwt.token;
    if (
      (options.method === 'POST' || options.method === 'PUT') &&
      (options.headers && !options.headers.hasOwnProperty('Content-Type'))
    ) {
      options.headers = {
        'Content-Type': 'application/json',
      };
    }

    const promise = fetch(url.toString(), {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
      signal,
    });

    if (!decode) {
      return promise;
    }

    const response = await promise;
    await this.validateResponse(response);
    if (response.status === 204) {
      return Promise.resolve();
    }
    try {
      const data = await response.json();
      return data.data;
    } catch (error) {
      throw new Error('Invalid JSON response');
    }
  }

  protected async validateResponse(response: Response): Promise<void> {
    if (response.ok) {
      return;
    }

    let data;

    try {
      data = await response.json();
    } catch (error) {
      throw new Error(response.statusText);
    }

    let errorMessage = null;

    if (data.error?.details.length) {
      errorMessage = data.error.details;
    }

    if (data.error?.details_it.length) {
      errorMessage = data.error.details_it;
    }

    if (typeof errorMessage === 'string') {
      throw new Error(errorMessage);
    }

    if (Array.isArray(errorMessage) && errorMessage !== null) {
      throw new Error(errorMessage.join(', '));
    }

    throw new Error(response.statusText);
  }

  protected async downloadWithAuth(
      url: URL,
      options: RequestInit = {method: 'GET'},
  ): Promise<Blob> {
    const response = await this.fetchWithAuth(url, options, false);
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.blob();
  }

  protected getPaginationParams(pagination?: Pagination): URLSearchParams {
    const params = new URLSearchParams();
    if (pagination) {
      params.append('itemsToTake', String(pagination.perPage));
      params.append('itemsToSkip', String(pagination.perPage * (pagination.page - 1)));
    }
    return params;
  }

  protected getSortingParams(sorting?: Sorting): URLSearchParams {
    const params = new URLSearchParams();
    if (sorting) {
      params.append('sort', sorting.direction === 'asc' ? sorting.field : `-${sorting.field}`);
    }
    return params;
  }

  protected getFilterParams(filters: Filter[] = []): URLSearchParams {
    const params = new URLSearchParams();
    filters.forEach((filter) => {
      if (!filter.value) {
        return;
      }
      params.append(filter.field, filter.value);
    });
    return params;
  }

  protected getRequestParams(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting): URLSearchParams {
    return new URLSearchParams({
      ...Object.fromEntries(this.getPaginationParams(pagination)),
      ...Object.fromEntries(this.getSortingParams(sorting)),
      ...Object.fromEntries(this.getFilterParams(filters)),
    });
  }
}
