/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import LegalData from '@/services/data/LegalData';
import {TermsConditionsInterface} from '@/types/entities';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export const useLegalStore = defineStore('LegalStore', {
  state: () => {
    return {
      termsConditions: {} as TermsConditionsInterface,
    };
  },
  actions: {
    async list() {
      try {
        this.termsConditions = await (new LegalData()).list();
      } catch (error) {
        ErrorHandlerService.handle(error);
      }
    },
  },
});
