import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-gray2 rounded-4 px-2 text-nowrap" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-baseline" }
const _hoisted_3 = { class: "d-flex flex-column" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-column ms-2"
}
const _hoisted_5 = { class: "text-gray4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productsCode, (productCode, i) => {
          return (_openBlock(), _createElementBlock("span", { key: i }, _toDisplayString(productCode), 1))
        }), 128))
      ]),
      (_ctx.row.orderDetails.length > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_5, " +" + _toDisplayString(_ctx.row.orderDetails.length - 1), 1),
              _createElementVNode("button", {
                class: "btn btn-sm btn-link text-dark",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAll = !_ctx.showAll))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["bi", [_ctx.showAll ? 'bi-chevron-up' : 'bi-chevron-down' ]])
                }, null, 2)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}