<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <line-chart-generator
    :css-classes="cssClasses"
    class="chart-container"
  />
</template>

<script lang="ts">
import {Line as LineChartGenerator} from 'vue-chartjs/legacy';
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement} from 'chart.js';
import {Line} from 'vue-chartjs';
import {defineComponent, h, type PropType} from 'vue';

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, CategoryScale, PointElement);

export default defineComponent({
  name: 'LineChart',
  components: {
    LineChartGenerator,
  },
  expose: ['updateChart'],
  props: {
    cssClasses: {
      default: '',
      type: String as PropType<string>,
    },
    datasets: {
      type: Array,
      default: () => [],
      required: true,
    },
    labels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props: any) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    return () =>
      h(Line, {
        chartData: {
          labels: props.labels,
          datasets: props.datasets,
        },
        chartOptions,
        chartId: 'line-chart',
        width: 400,
        height: 400,
        cssClasses: props.cssClasses,
        styles: {},
        plugins: [],
      });
  },
});
</script>


<style>
.chart-container {
  position: relative;
  height: 100%;
}
</style>
