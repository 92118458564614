/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {JwtInterface, LoginResponseInterface} from '@/types/entities';
import UserData from '@/services/data/UserData';
import SessionService from '@/services/SessionService';
import StoreCleanerService from '@/services/StoreCleanerService';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export const useSessionStore = defineStore('SessionStore', {
  state: () => {
    let jwt = localStorage.getItem('jwt');
    try {
      jwt = jwt ? JSON.parse(jwt) : null;
    } catch (e) {
      jwt = null;
    }

    return {
      sessionService: new SessionService(),
      storeCleanerService: new StoreCleanerService(),
      jwt: jwt as JwtInterface | null,
      refreshingSession: false,
    };
  },
  actions: {
    async login(email: string, password: string): Promise<LoginResponseInterface> {
      const response = await (new UserData()).login(email, password);
      this._storeToken(response);
      return response;
    },
    async refresh(): Promise<void> {
      if (!this.canRefresh || !this.jwt) {
        throw new Error('No JWT found');
      }
      this.refreshingSession = true;
      try {
        const response = await (new UserData()).refresh(this.jwt.refresh_token);
        this._storeToken(response);
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.logout();
        throw error;
      } finally {
        this.refreshingSession = false;
      }
    },
    _storeToken(response: LoginResponseInterface): void {
      const jwtExpirationDate = new Date(+new Date() + (process.env.VUE_APP_JWT_TTL * 1000));
      this.jwt = {
        token: response.token.token,
        refresh_token: response.token.refresh_token,
        expiration: +jwtExpirationDate,
      };
      localStorage.setItem('jwt', JSON.stringify(this.jwt));
      this.sessionService.clearSession();
      this.sessionService.setupSession(jwtExpirationDate);
    },
    logout() {
      this.clear();
      this.sessionService.clearSession();
      this.storeCleanerService.clearStores();
    },
    clear() {
      this.jwt = null;
      localStorage.removeItem('jwt');
    },
  },
  getters: {
    isLoginValid(): boolean {
      return !!this.jwt && this.jwt.expiration > +new Date();
    },
    canRefresh(): boolean {
      return this.jwt !== null && this.jwt.refresh_token !== null;
    },
  },
});
