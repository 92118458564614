<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div
    id="login"
    class="row m-0"
  >
    <section
      class="col-lg-5 col-md-6 col-sm-12 d-flex justify-content-center align-items-center"
    >
      <div
        class="w-50 text-secondary"
      >
        <h1 class="display-6 pb-3 text-uppercase">
          <span v-if="userIsLoggingIn">Entra in</span>
          <span v-else-if="userIsRegistering">Registrazione</span>
          <span
            v-else-if="userIsRecoveringPassword"
            class="text-nowrap"
          >Recupera password</span>
        </h1>
        <img
          src="@/assets/logo-greenkar.svg"
          alt="logo"
          class="img-fluid pb-5"
        >
        <div
          v-if="userIsRecoveringPassword"
          class="d-flex justify-content-center bg-gray2 rounded-4 p-3 mb-5"
        >
          <span class="bi bi-exclamation-circle fs-5 text-green3" />
          <span class="mt-1 ms-1">
            Inserisci l'indirizzo e-mail con il quale ti sei registrato e clicca poi
            su “invia richiesta“. Entro pochi minuti riceverai una nostra e-mail,
            tramite la quale sarà possibile immettere una nuova password.
          </span>
        </div>
        <form
          class="pb-5"
          @submit.prevent="onSubmit"
        >
          <fieldset class="text-uppercase">
            <input
              v-model="email"
              type="text"
              class="form-control mb-4"
              placeholder="indirizzo email"
            >
            <input
              v-if="!userIsRecoveringPassword"
              v-model="password"
              type="password"
              class="form-control"
              :class="userIsRegistering ? 'mb-2' : 'mb-3'"
              :placeholder="userIsRegistering ? 'scegli password' : 'password'"
            >
            <!--            TODO: add checkbox to keep connection-->
            <p
              v-if="userIsRegistering"
              class="text-lowercase ms-2"
            >
              almeno 8 caratteri, una maiuscola, un numero, un carattere speciale !?=_#
            </p>
            <input
              v-if="userIsRegistering"
              v-model="dealerCode"
              type="text"
              class="form-control mb-4"
              placeholder="il tuo codice dealer"
            >
            <div
              v-if="userIsRegistering"
              class="mb-4"
            >
              <label
                for="termsCheckbox"
                class="text-lowercase"
              >
                <input
                  id="termsCheckbox"
                  v-model="termsCheckboxChecked"
                  type="checkbox"
                  class="d-none"
                >
                <span
                  class="bi pe-1"
                  :class="termsCheckboxChecked ? 'bi-check-circle' : 'bi-circle'"
                />
                Confermo di aver letto e di accettare i
                <a
                  href="/assets/termini_e_condizioni.pdf"
                  target="_blank"
                >termini e condizioni</a>
              </label>

              <label
                for="privacyPolicyCheckbox"
                class="text-lowercase mt-2"
              >
                <input
                  id="privacyPolicyCheckbox"
                  v-model="privacyPolicyCheckboxChecked"
                  type="checkbox"
                  class="d-none"
                >
                <span
                  class="bi pe-1"
                  :class="privacyPolicyCheckboxChecked ? 'bi-check-circle' : 'bi-circle'"
                />
                Confermo di aver letto e di accettare la
                <a
                  href="/assets/privacy.pdf"
                  target="_blank"
                >privacy policy</a>
              </label>
            </div>
            <div
              class="d-flex justify-content-start"
              :class="{'mt-5': userIsRecoveringPassword}"
            >
              <button
                type="submit"
                class="btn btn-green5 mx-2 text-uppercase text-white me-4"
              >
                <span v-if="userIsLoggingIn">accedi</span>
                <span v-else-if="userIsRegistering">registrati</span>
                <span v-else-if="userIsRecoveringPassword">invia richiesta</span>
              </button>
              <button
                v-if="userIsLoggingIn"
                type="button"
                class="btn btn-gray2 text-secondary text-uppercase"
                @click="userIsLoggingIn = false; userIsRegistering = true"
              >
                Registrati
              </button>
              <button
                v-if="userIsRecoveringPassword"
                type="button"
                class="btn btn-gray2 text-secondary"
                @click="userIsRecoveringPassword = false; userIsLoggingIn = true"
              >
                <span class="bi bi-arrow-left me-2" /> Torna al login
              </button>
            </div>
          </fieldset>
        </form>
        <div
          v-if="userIsRegistering"
          class="d-flex registration-info mt-3"
        >
          <span class="bi bi-exclamation-circle fs-5 text-green3" />
          <span class="mt-1 ms-1">
            Tutte le comunicazioni da parte del Portale saranno inviate
            all'email indicata in questa fase
          </span>
        </div>
        <button
          v-if="userIsRegistering"
          class="btn btn-gray2 mt-3 px-3 text-secondary"
          style="z-index: 3"
          @click="userIsRegistering = false; userIsLoggingIn = true"
        >
          login utente già registrato <span class="bi bi-arrow-right ms-2" />
        </button>
        <button
          v-if="userIsLoggingIn"
          class="btn btn-gray2 mt-3 px-3 text-secondary"
          @click="userIsLoggingIn = false; userIsRecoveringPassword=true"
        >
          recupera password <span class="bi bi-arrow-right ms-2" />
        </button>
      </div>
      <div
        class="w-50 py-3 bottom-0 position-absolute text-center footer-text"
      >
        <span>
          Greenkar Innovation s.r.l. - Sede legale: via Riva Ligure, 49 - 00163 RM P.I. 02671430425 | REA RM 1563567<br>
          tel:
          <a
            href="tel:+39063328514"
            class="text-decoration-none footer-text"
          >
            +39 06.3328514
          </a>
          - e-mail:
          <a
            href="mailto:autocarri@greenkar-sv.it"
            class="text-decoration-none footer-text"
          >
            autocarri@greenkar-sv.it
          </a>
        </span>
      </div>
    </section>
    <section
      id="background"
      class="col-lg-7 col-md-6 d-none d-sm-none d-md-flex"
    >
      <div class="w-100 align-self-end d-flex justify-content-end align-items-end">
        <img
          id="logo-background"
          src="@/assets/logo-greenkar-white.svg"
          alt="logo"
        >
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useUserStore} from '@/stores/UserStore';
import {useToast} from 'vue-toastification';
import {useSessionStore} from '@/stores/SessionStore';
import User from '@/types/User';

export default defineComponent({
  name: 'LoginPage',
  setup() {
    return {
      userStore: useUserStore(),
      sessionStore: useSessionStore(),
      toastService: useToast(),
    };
  },
  data() {
    return {
      email: '',
      password: '',
      dealerCode: '',
      userIsLoggingIn: true,
      userIsRegistering: false,
      userIsRecoveringPassword: false,
      termsCheckboxChecked: false,
      privacyPolicyCheckboxChecked: false,
    };
  },
  methods: {
    onSubmit: async function() {
      if (this.userIsLoggingIn) {
        await this.login();
        return;
      } else if (this.userIsRegistering) {
        if (!this.termsCheckboxChecked || !this.privacyPolicyCheckboxChecked) {
          (useToast()).error('Devi accettare i termini prima di proseguire');
        }
        await this.register();
        return;
      } else if (this.userIsRecoveringPassword) {
        await this.recoverPassword();
        return;
      }
    },
    login: async function() {
      try {
        const response = await this.sessionStore.login(this.email, this.password);
        this.userStore.userOperations = response.operations;
        this.userStore.currentUser = new User(response.user);
        this.$router.push({name: 'Dashboard'});
      } catch (e) {
        if (e instanceof Error) {
          (useToast()).error(e.message);
        } else {
          (useToast()).error('Errore sconosciuto');
        }
      }
    },
    register: async function() {
      try {
        await this.$userData.registration(this.email, this.password, this.dealerCode );
        this.email = '';
        this.password = '';
        this.dealerCode = '';
        (useToast()).success('Registrato con successo');
        this.userIsRegistering = false;
        this.userIsLoggingIn = true;
      } catch (e) {
        if (e instanceof Error) {
          (useToast()).error(e.message);
        } else {
          (useToast()).error('Errore sconosciuto');
        }
      }
    },
    recoverPassword: async function() {
      try {
        await this.$userData.resetPassword(this.email);
        this.email = '';
        (useToast()).success(`L'email con la nuova password è stata inviata`);
        this.userIsRecoveringPassword = false;
        this.userIsLoggingIn = true;
      } catch (e) {
        if (e instanceof Error) {
          (useToast()).error(e.message);
        } else {
          (useToast()).error('Errore sconosciuto');
        }
      }
      return;
    },
  },
});
</script>

<style scoped lang="scss">
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/variables";
  @import "bootstrap/scss/mixins";
  @import "src/styles/variables.scss";

  #login {
    height: 100%;
  }
  #background {
    background-image: url('@/assets/BG02.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  #logo-background {
    width: 15%;
    margin: 2rem;
  }

  .form-control {
    border-top: none;
    border-left: none;
    border-right: none;

    &::placeholder {
      text-transform: uppercase;
    }
  }

  .btn-eag-gradient {
    @include button-variant(
        map-get($custom-colors, "green1"),
        transparent
    );
    background: linear-gradient(90deg, map-get($custom-colors, "green1") 0%, map-get($custom-colors, "green2") 96%);
    color: map-get($custom-colors, "gray1");
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .registration-info{
    font-size: 12.5px;
  }

  input[type=password] + p{
    font-size: 10px;
  }

  .footer-text {
    font-size: 12px;
    color: #999;
  }
</style>
