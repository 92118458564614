<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <main-body v-if="windowWidth > 1343" />
  <div
    v-else
    class="d-flex justify-content-center pt-5 vh-100 bg-gray1"
  >
    <div class="pt-5 mt-5 mx-3">
      <img
        src="@/assets/logo-greenkar.svg"
        alt="logo"
        class="img-fluid pb-5 w-100"
      >
      <div class="mx-5 fs-4 mt-3 text-center">
        Per usufruire del portale è necessario accedere da computer
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useSessionStore} from '@/stores/SessionStore';
import MainBody from '@/components/Root/MainBody.vue';

export default defineComponent({
  name: 'App',
  components: {MainBody},
  setup() {
    {
      return {
        sessionStore: useSessionStore(),
      };
    }
  },
  data() {
    return {
      windowWidth: window.screen.width,
    };
  },
  created() {
    window.addEventListener('resize',
        () => {
          this.windowWidth = window.screen.width;
        },
    );
  },
  unmounted() {
    window.removeEventListener('resize',
        () => {
          this.windowWidth = window.screen.width;
        },
    );
  },
  mounted() {
    this.sessionStore.sessionService.on('jwt-expired', this.refreshSession);
  },
  methods: {
    refreshSession: async function() {
      if (!this.sessionStore.canRefresh) {
        this.sessionStore.logout();
        this.$router.push({name: 'LoginPage'});
        return;
      }
      try {
        await this.sessionStore.refresh();
      } catch (e) {
        this.sessionStore.logout();
        this.$router.push({name: 'LoginPage'});
      }
    },
  },
});
</script>
