/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {CartInterface, CartItemInterface} from '@/types/entities';
import CartData from '@/services/data/CartData';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export const useCartStore = defineStore('CartStore', {
  state: () => {
    return {
      cart: {
        shippingCost: 0,
        products: [] as CartItemInterface[],
      } as CartInterface,
    };
  },
  actions: {
    async get() {
      try {
        this.cart = await (new CartData()).get();
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
        throw error;
      }
    },
    clear() {
      this.cart = {} as CartInterface;
    },
  },
});
