<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="d-flex">
    <span
      class="bi fs-5"
      :class="[row.orderDetails.length > 1 ? 'bi-boxes' : 'bi-box']"
    />
    <span class="ps-2">
      x
      <bootstrap-table-data
        :row="row"
        :column="column"
      />
    </span>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Order from '@/types/Order';
import {Column} from '@/types/entities';
import BootstrapTableData from '@/components/BootstrapTable/BootstrapTableData.vue';

export default defineComponent({
  name: 'OrderDetails',
  components: {BootstrapTableData},
  props: {
    row: {
      type: Object as PropType<Order>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
});
</script>
