/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import OrderData from '@/services/data/OrderData';
import Order from '@/types/Order';
import {Filter, OrderPaymentMethod, Pagination, Sorting} from '@/types/entities';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export const useOrderStore = defineStore('OrderStore', {
  state: () => {
    return {
      orders: [] as Order[],
      currentOrder: {} as Order,
      selectedPaymentMethod: 'wireTransfer' as keyof typeof OrderPaymentMethod,
      activeSorting: {} as Sorting,
      activeFilters: [] as Filter[],
      pagination: {} as Pagination,
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      const orders = await (new OrderData()).list(pagination, filters, sorting, signal);
      this.orders = orders.map((order) => new Order(order));
    },
    async get(orderId: number) {
      try {
        const orderData = await (new OrderData()).get(orderId);
        this.currentOrder = new Order(orderData);
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
      }
    },
    clear() {
      this.orders = [];
      this.currentOrder = {} as Order;
      this.activeSorting = {} as Sorting;
      this.activeFilters = [] as Filter[];
      this.pagination = {} as Pagination;
    },
  },
  getters: {
    isOrderShipped(): boolean {
      return this.currentOrder && this.currentOrder.status === 'shipped';
    },
  },
});
