/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {CartInterface} from '@/types/entities';

export default class CartData extends Api {
  get(): Promise<CartInterface> {
    const url = new URL(`${this.apiUrl}/cart`);
    return this.fetchWithAuth(url);
  }

  addProduct(vehicleFrameId: number, productId: number, productQuantity?: number): Promise<{id:string}> {
    const url = new URL(`${this.apiUrl}/cart`);

    const data = {
      vehicleFrame: vehicleFrameId,
      product: productId,
      quantity: productQuantity,
    };

    if (productQuantity === undefined) {
      delete data.quantity;
    }

    return this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  removeProduct(cartItemId: number): Promise<string> {
    const url = new URL(`${this.apiUrl}/cart/${cartItemId}`);
    return this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  emptyCart(): Promise<{data: [], error: []}> {
    const url = new URL(`${this.apiUrl}/cart`);
    return this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }
}
