/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {Component} from 'vue';

export interface AddressInterface {
  id: number
  userId: number
  street: string
  city: string
  zip: number
  country: string
  type: string
  province: string
  main: boolean
}

export type UpdateAddressInterface = Omit<AddressInterface, 'id'>

export interface CartInterface {
  netAmount: number
  shippingCost: number
  vatAmount: number
  totalAmount: number
  products: CartItemInterface[]
}

export interface CartItemInterface {
  id: number
  quantity: number
  product: ProductInterface
  vehicleFrame: VehicleFrameInterface
}

export interface DocumentInterface {
  id: number
  title: string
  type: string | null
  filename: string
  mimeType: string
  creationDate: string
  orderNumber: number | null
  vehicleFrameNum: number | null
  fileSize: string | null
}

export interface DocumentUploadInterface {
  filename: string
  orderDetail?: number
  type?: string
}

export enum NewsUserType {
  dealer = 'Concessionario',
  carMaker = 'Costruttore',
  vendor = 'Fornitore',
  all = 'Tutti'
}

export interface NewsInterface {
  id: number
  active: boolean
  title: string
  content: string
  userType: [keyof typeof NewsUserType][]
  carMaker: string[]
  deactivationDate: string
  creationDate: string
}

export interface NewsUpsertRequestInterface extends Omit<NewsInterface, 'id'|'active'|'creationDate'> {
  id?: number
}

export enum OrderStatus {
  created = 'In attesa di pagamento',
  payed = 'Pagato in attesa di spedizione',
  shipped = 'Spedito / concluso',
  canceled = 'Annullato',
}

export interface OrderInterface {
  id: number
  number: number
  orderDate: string
  processingDate: string | null
  netAmount: string
  shippingCost: string
  vatAmount: string
  totalAmount: string
  status: keyof typeof OrderStatus
  trackId: string
  paymentId: string
  paymentMethod: keyof typeof OrderPaymentMethod
  invoicing: AddressInterface,
  shipping: AddressInterface,
  orderDetails: OrderDetailInterface[],
  courier: CarrierInterface | null,
  carMaker: UserInterface,
  customer: UserInterface,
  vendor: UserInterface,
  address:{
    invoicing: AddressInterface
    shipping: AddressInterface
  },
  newDocument: boolean
}

export enum OrderPaymentMethod {
  Paypal = 'PayPal',
  wireTransfer = 'Bonifico bancario',
}

export interface OrderUpdateRequestInterface {
  status?: keyof typeof OrderStatus
  trackId?: string
  carrier?: string
  newDocument?: boolean
}

export interface OrderDetailInterface {
  correctAssemblyConfirmation: boolean,
  correctAssemblyConfirmationTime: number,
  id: number,
  paidVehicleAntiforgeryCode: string,
  paidVehicleConfirmation: boolean,
  paidVehicleConfirmationTime: number,
  quantity: number,
  price: number,
  dcaDocumentId: number | null,
  manualDocumentId: number | null,
  authorizationAsvId: number | null,
  authorizationKiaId: number | null,
  technicalReportId: number | null,
  testingDate: string,
  product: ProductInterface,
  vehicleFrame: VehicleFrameInterface
}

export interface OrderCreationInterface {
  customer: number
  status?: string
  paymentMethod?: string
  paymentId?: number
  invoicingAddress: number
  shippingAddress: number
}

export interface OrderPaymentMethodInterface {
  wiretransfer: {
    accountholder: string,
    bank: string,
    iban: string,
    bic: string,
    reason: string
  }
}

export enum PaymentStatusQueryParam {
  OK = 'OK',
  KO = 'KO',
}

export interface TermsConditionsInterface {
  correctAssemblyConfirmation: string,
  paidVehicleConfirmation: string,
  cartTermsAndConditions: string,
}

export interface CarrierInterface {
  id: number
  name: string
  trackingLink: string
}

export interface ProductInterface {
  id: number
  code: string
  greenkarCode: string
  name: string
  model: string
  categoryId: number
  category: CategoryInterface
  price: string
  active: boolean
  reactivationDate: string
  quantityAvailable: number
  vendor: UserInterface
}

export interface ProductUpdateRequestInterface extends Omit<ProductInterface, 'id'|'category'|'vendor'> {
  category: string
  vendor: string
}

export interface CategoryInterface {
  id: number
  name: string
  active: boolean
}

export interface UserInterface {
  id: number
  email: string
  phone: string
  active: boolean
  role: keyof typeof UserRole
  changePassword: boolean
  dealerCode: string
  fiscalCode: string | null
  SDICode: string | null
  PECAddress: string | null
  businessName: string | null
  contactName: string | null
  legalRepresentative: string | null
  vatNumber: string | null
  shippingCost: number | null
  invoicingType: string | null
  logo: string | null
  mainUser: UserInterface | null
  carMaker: UserInterface | null
  password?: string,
  courtesyInvoiceEmail: string | null
}

export interface UpdateUserInterface extends Omit<UserInterface, 'id'|'mainUser'|'carMaker'>{
  mainUser: string
  carMaker: string
}

export interface UpdateUserDataInterface extends Omit<UserInterface, 'id'|'mainUser'|'carMaker'|'active'|'role'|'dealerCode'|'shippingCost'|'invoicingType'>{
  mainUser?: string
  carMaker?: string
  active?: boolean
  role?: keyof typeof UserRole
  dealerCode?: string
  shippingCost?: number | null
  invoicingType?: string | null
}

export enum UserRole {
  ROLE_GREENKAR = 'Greenkar',
  ROLE_CARMAKER = 'Costruttore',
  ROLE_DEALER = 'Concessionario',
  ROLE_VENDOR = 'Fornitore',
}


export enum UserInvoicingType {
  single = 'Fattura immediata',
  monthly = 'Fattura mensile',
}

export type UserOperations = {
  menuItems: {
    cart: boolean,
    dashboard: boolean,
    vehicleFrame: boolean,
    order: boolean,
    document: boolean,
    product: boolean,
    user: boolean,
    profile: boolean,
    logout: boolean
  },
  cart: {
    cart: boolean,
    productList: boolean,
    dataSummary: boolean,
    payment: boolean,
    forwarded: boolean,
    cartSection: {
      productList: boolean,
      summary: boolean,
      continueButton: boolean
    },
    dataSummarySection: {
      invoiceRecipient: boolean,
      shippingAddress: boolean,
      shippingAddresEditButtonPopUp: boolean,
      termsAndConditions: boolean,
      acceptTermsAndConditionsButton: boolean,
      summary: boolean,
      continueButton: boolean
    },
    paymentSection: {
      paymentMethodPickList: boolean,
      summary: boolean,
      continueButton: boolean
    },
    forwardedSection: {
      productList: boolean,
      invoiceRecipient: boolean,
      shippingAddress: boolean,
      summary: boolean,
      goToOrderButton: boolean,
      paymentMethod: boolean,
      uploadDepositSlipButtonPopup: boolean
    }
  },
  dashboard: {
    newsWidget: {
      view: boolean,
      edit: boolean,
      addPopup: boolean
    },
    dcaWidget: {
      view: boolean,
      download: boolean
    },
    orderWidget: {
      view: boolean
    },
    statsWidget: {
      averageProcessing: boolean,
      orderCount: boolean,
      orderCountPerStatus: boolean,
    }
  },
  vehicleFrame: {
    listView: {
      view: boolean,
      detailViewButton: boolean,
      uploadFileQstockButtonPopup: boolean,
      historyImportsButtonPopup: boolean,
      addToCartButtonPopup: boolean
    },
    addToCartButtonPopup: {
      viewProductList: boolean,
      addToCartButton: boolean
    },
    addToCartButton: {
      viewConfirmSelection: boolean
    },
    detailView: {
      orderDetail: boolean,
      editButton: boolean,
      editView: boolean,
      releatedProducts: boolean
    },
    historyImports: {
      viewImports: boolean
    }
  },
  order: {
    listView: {
      detailView: boolean,
      editViewPopupStatusButton: boolean,
      editViewPopupCourierAndTrackIdButton: boolean,
      downloadLabelsButton: boolean,
      downloadAddressesButton: boolean,
      downloadOrdersButton: boolean,
    },
    detailView: {
      payment: {
        view: boolean
      },
      orderDetail: {
        view: boolean,
        editViewPopupStatusButton: boolean,
        downloadAuthorizationASV: boolean,
        downloadAuthorizationKIA: boolean,
        downloadTechnicalReport: boolean,
        uploadAuthorizationASV: boolean,
        uploadAuthorizationKIA: boolean,
        uploadTechnicalReport: boolean,
      },
      shipping: {
        view: boolean
        editViewPopupCourierAndTrackIdButton: boolean
      },
      billingName: {
        view: boolean
      },
      shippingAddress: {
        view: boolean
      },
      dealerDetail: {
        view: boolean
      },
      documentList: {
        view: boolean,
        detailView: boolean,
        download: boolean,
        upload: boolean
      },
      wireTransferDetails: {
        view: boolean
      },
      productList: {
        viewProduct: boolean,
        downloadAssemblyManual: boolean,
        dcaRequest: boolean,
        dcaDownload: boolean,
        viewFlags: boolean,
        insertTestingDate: boolean,
      },
      cost: {
        view: boolean
      }
    }
  },
  document: {
    listView: {
      download: boolean
    }
  },
  product: {
    listView: {
      detailViewButton: boolean,
      uploadFileKitPopup: boolean,
      historyImportsButtonPopup: boolean
    },
    detailView: {
      productView: boolean,
      releatedVehicleFrames: boolean,
      editButton: boolean,
      editView: boolean
    }
  },
  user: {
    listView: {
      detailView: boolean,
      addUserButtonView: boolean
    },
    detailView: {
      userProfileView: boolean,
      editUserProfileButton: boolean,
      editInvoicingData: boolean,
      cretentialsView: boolean,
      editCredentialsButton: boolean,
      invoicingDetailsView: boolean,
      editInvoicingDetailsButton: boolean,
      shippingAddressView: boolean,
      editShippingAddressButton: boolean,
      addShippingAddressButtonPopup: boolean
    },
    addUserButtonView: {
      userProfileView: boolean,
      cretentialsView: boolean,
      invoicingDetailsView: boolean,
      shippingAddressView: boolean
    }
  },
  profile: {
    userProfileView: boolean,
    editUserProfileButton: boolean,
    cretentialsView: boolean,
    editCredentialsButton: boolean,
    invoicingDetailsView: boolean,
    editInvoicingDetailsButton: boolean,
    shippingAddressView: boolean,
    editShippingAddressButton: boolean,
    addShippingAddressButtonPopup: boolean
  },
  logout: boolean
}


export interface VehicleFrameInterface {
  id: number
  vehicleFrameNum: number
  model: string
  type: string
  vehicleCategory: string
  carBody: string
  seatsN1: number
  MC: number
  train: number
  maxWeightAxle1: number
  maxWeightAxle2: number
  commercialDescription: string
  carryingCapacity: number
  registrationCodeN1: string
  registrationCodeDateN1: string
  ordersUsageCount: number
  carMaker: UserInterface
  dealer: UserInterface
}

export interface VehicleFrameUpdateRequestInterface extends Omit<VehicleFrameInterface, 'id'|'carMaker'|'dealer'|'ordersUsageCount'> {
  carMaker: string
  dealer: string
}

export interface OrderStatsInterface {
  averageProcessing: { [key: string]: number },
  orderCount: {
    [key in keyof typeof OrderStatus]: number
  },
  orderCountPerStatus: { [key: string]: {[key: string]: number}}
}

export enum ImportStatus {
  'on queue' = 'In coda',
  processing = 'In elaborazione',
  imported = 'Importato',
  failed = 'Errore',
}

export interface ImportResultInterface {
  id: number
  filename: string
  status: keyof typeof ImportStatus
  errorLog: string
  creator: UserInterface
  inputDate: string
  processEndDate: string
}

export interface chartDataInterface {
  labels: Array<string>;
  datasets: [{ data: Array<number> }]
}

export interface LoginResponseInterface {
  user: UserInterface
  operations: UserOperations
  token: Omit<JwtInterface, 'expiration'>
}

export interface RegistrationResponseInterface {
  id: number
  email: string
  active: boolean
}

export interface JwtInterface {
  token: string
  refresh_token: string
  expiration: number
}

type SortDirection = 'asc' | 'desc';

export type Sorting = {
  field: string; // this is the field name used in the API
  direction: SortDirection;
};

export type FilterConfig = {
  field: string; // this is the field name in the API
  type: 'date' | 'text' | 'select'; // this is how the filter should be rendered
  options?: { value: string; label: string }[]; // this is the list of options for the select
}

export type Pagination = {
  page: number;
  perPage: number;
};

export type Column = {
  title: string;
  field: string; // this is the field name used in the API for sorting
  fieldAlias?: string; // this is the field name used in the frontend to display the data (default: field)
  sortable: boolean;
  filters?: FilterConfig[];
  component?: Component;
};

export type Filter = {
  field: string;
  value: string;
}

export type BootstrapTableProps = {
  columns: Column[];
  data: unknown[];
  loading: boolean;
  sorting: Sorting;
  pagination: Pagination;
  activeFilters: Filter[];
};

export interface DocumentUpdateInterface {
  title?: string;
  productId?: number;
}

export interface OrderDetailUpdateRequestInterface {
  testingDate?: string;
}
