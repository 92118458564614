/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import Api from '@/services/data/Api';
import {
  Filter,
  OrderCreationInterface, OrderDetailUpdateRequestInterface,
  OrderInterface, OrderPaymentMethodInterface, OrderStatsInterface,
  OrderUpdateRequestInterface, Pagination, Sorting,
} from '@/types/entities';
import Order from '@/types/Order';

export default class OrderData extends Api {
  list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal): Promise<OrderInterface[]> {
    const url = new URL(`${this.apiUrl}/order`);

    url.search = this.getRequestParams(pagination, filters, sorting).toString();

    return this.fetchList(url, signal);
  }

  get(orderId: number): Promise<OrderInterface> {
    const url = new URL(`${this.apiUrl}/order/${orderId}`);
    return this.fetchWithAuth(url).then((response) => {
      return response;
    });
  }

  createOrder(data: OrderCreationInterface): Promise<Order[]> {
    const url = new URL(`${this.apiUrl}/order`);
    return this.fetchWithAuth(
        url,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
    );
  }

  update(orderId: number, data: OrderUpdateRequestInterface): Promise<{id: string}> {
    const url = new URL(`${this.apiUrl}/order/${orderId}`);
    return this.fetchWithAuth(
        url,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
    ) as Promise<{id: string}>;
  }

  stats(): Promise<OrderStatsInterface> {
    const url = new URL(`${this.apiUrl}/order/stats`);
    return this.fetchWithAuth(url);
  }

  requestDCA(orderId: number, orderDetailId:number, paidVehicleAntiforgeryCode: string, paidVehicleConfirmation: boolean, correctAssemblyConfirmation: boolean): Promise<any> {
    const url = new URL(`${this.apiUrl}/order/${orderId}/detail/${orderDetailId}`);
    return this.fetchWithAuth(
        url,
        {
          method: 'PUT',
          body: JSON.stringify({
            paidVehicleAntiforgeryCode,
            paidVehicleConfirmation,
            correctAssemblyConfirmation,
          }),
        },
    );
  }

  getPaymentMethod(orderId: number): Promise<OrderPaymentMethodInterface> {
    const url = new URL(`${this.apiUrl}/order/${orderId}/payment-method`);
    return this.fetchWithAuth(url).then((response) => {
      return response;
    });
  }

  payWithPaypal(ordersIds: number[]): Promise<{url: string}> {
    const url = new URL(`${this.apiUrl}/order/pay/paypal`);
    return this.fetchWithAuth(url,
        {
          method: 'PUT',
          body: JSON.stringify({ids: ordersIds}),
        }).then((response) => {
      return response;
    });
  }

  downloadLabels(): Promise<Blob> {
    return this.downloadWithAuth(
        new URL(`${this.apiUrl}/order/label/download`),
        {
          method: 'GET',
        },
    );
  }

  downloadAddresses(): Promise<Blob> {
    return this.downloadWithAuth(
        new URL(`${this.apiUrl}/order/address/download`),
        {
          method: 'GET',
        },
    );
  }

  downloadOrders(year: number): Promise<Blob> {
    return this.downloadWithAuth(
        new URL(`${this.apiUrl}/order/download/${year}`),
        {
          method: 'GET',
        },
    );
  }

  updateOrderDetail(orderId: number, orderDetailId:number, data: OrderDetailUpdateRequestInterface): Promise<any> {
    const url = new URL(`${this.apiUrl}/order/${orderId}/detail/${orderDetailId}`);
    return this.fetchWithAuth(
        url,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
    );
  }
}
