<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <template
    v-if="cleanView"
  >
    <router-view class="vh-100" />
  </template>
  <template v-else>
    <div class="row g-0">
      <div class="col-xl-2 col-3 vh-100">
        <main-sidebar />
      </div>
      <div class="col-xl-10 col-9 vh-100 bg-gray2 d-flex flex-column">
        <main-header />
        <main-view>
          <template #default>
            <router-view />
          </template>
        </main-view>
        <main-footer />
      </div>
    </div>
  </template>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import MainSidebar from '@/components/Root/MainSidebar.vue';
import MainHeader from '@/components/Root/MainHeader.vue';
import MainView from '@/components/Root/MainView.vue';
import MainFooter from '@/components/Root/MainFooter.vue';

export default defineComponent({
  name: 'MainBody',
  components: {MainView, MainHeader, MainSidebar, MainFooter},
  computed: {
    cleanView: function() {
      return this.$route.meta.cleanView;
    },
  },
});
</script>
