import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    target: "_blank",
    href: _ctx.row.courier?.trackingLink,
    class: _normalizeClass({ 'text-decoration-none text-dark': !_ctx.row.courier?.trackingLink })
  }, _toDisplayString(_ctx.row.trackId), 11, _hoisted_1))
}