<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <pie-chart-generator
    :css-classes="cssClasses"
    class="chart-container"
  />
</template>

<script lang="ts">
import {Pie as PieChartGenerator} from 'vue-chartjs/legacy';
import {Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale} from 'chart.js';
import {Pie} from 'vue-chartjs';
import {defineComponent, h, PropType} from 'vue';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
  name: 'PieChart',
  components: {
    PieChartGenerator,
  },
  expose: ['updateChart'],
  props: {
    cssClasses: {
      default: '',
      type: String as PropType<string>,
    },
    datasets: {
      type: Array,
      default: () => [],
      required: true,
    },
    labels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props: any) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    return () =>
      h(Pie, {
        chartData: {
          labels: props.labels,
          datasets: props.datasets,
        },
        chartOptions,
        chartId: 'line-chart',
        width: 400,
        height: 400,
        cssClasses: props.cssClasses,
        styles: {},
        plugins: [],
      });
  },
});
</script>


<style>
.chart-container {
  position: relative;
  height: 100%;
}
</style>
