<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <table class="table table-sm">
    <bootstrap-table-head v-model="tableProps" />
    <bootstrap-table-body :table-props="tableProps" />
    <bootstrap-table-foot v-model="tableProps" />
  </table>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {BootstrapTableProps} from '@/types/entities';
import BootstrapTableHead from '@/components/BootstrapTable/BootstrapTableHead.vue';
import BootstrapTableBody from '@/components/BootstrapTable/BootstrapTableBody.vue';
import BootstrapTableFoot from '@/components/BootstrapTable/BootstrapTableFoot.vue';

export default defineComponent({
  name: 'BootstrapTable',
  components: {BootstrapTableFoot, BootstrapTableHead, BootstrapTableBody},
  props: {
    modelValue: {
      type: Object as PropType<BootstrapTableProps>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    tableProps: {
      get() {
        return this.modelValue;
      },
      set(value: BootstrapTableProps) {
        this.$emit('update:modelValue', value);
      },
    },
  },
});

</script>

<style lang="scss" scoped>
 table {
   border-collapse: collapse;
 }
</style>
