<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="bg-gray2 rounded-4 px-2 text-nowrap">
    <div class="d-flex justify-content-between align-items-baseline">
      <div
        class="d-flex flex-column"
      >
        <span
          v-for="(vehicleFrameNum, i) in vehicleFramesNum"
          :key="i"
        >
          {{ vehicleFrameNum }}
        </span>
      </div>
      <div
        v-if="row.orderDetails.length > 1"
        class="d-flex flex-column ms-2"
      >
        <div>
          <span class="text-gray4">
            +{{ row.orderDetails.length - 1 }}
          </span>
          <button
            class="btn btn-sm btn-link text-dark"
            @click="showAll = !showAll"
          >
            <span
              class="bi"
              :class="[showAll ? 'bi-chevron-up' : 'bi-chevron-down' ]"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Column, OrderDetailInterface} from '@/types/entities';
import Order from '@/types/Order';

export default defineComponent({
  name: 'OrderVehicleFrame',
  props: {
    row: {
      type: Object as PropType<Order>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    vehicleFramesNum(): number[] {
      if (!this.showAll) {
        return this.row.orderDetails.slice(0, 1).map((orderDetail: OrderDetailInterface) => orderDetail.vehicleFrame.vehicleFrameNum);
      }
      return this.row.orderDetails.map((orderDetail: OrderDetailInterface) => orderDetail.vehicleFrame.vehicleFrameNum);
    },
  },
});
</script>
