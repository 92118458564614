<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="text-end">
    <router-link
      v-if="userStore.userOperations.user?.listView.detailView"
      :to="{name: 'UserDetail', params: {userId: row.id}}"
      class="btn btn-sm btn-link text-dark"
    >
      <span class="bi bi-search" />
    </router-link>
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Column} from '@/types/entities';
import {useUserStore} from '@/stores/UserStore';
import User from '@/types/User';

export default defineComponent({
  name: 'ProductActions',
  props: {
    row: {
      type: Object as PropType<User>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
});
</script>
