/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {defineStore} from 'pinia';
import {AddressInterface, Filter, Pagination, Sorting, UserInterface, UserOperations} from '@/types/entities';
import UserData from '@/services/data/UserData';
import User from '@/types/User';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      users: [] as User[],
      currentUser: {} as User,
      selectedUser: {} as User,
      userOperations: {} as UserOperations,
      addresses: [] as AddressInterface[],
      shippingAddresses: [] as AddressInterface[],
      invoicingAddresses: [] as AddressInterface[],
      selectedShippingAddress: {} as AddressInterface,
      selectedInvoicingAddress: {} as AddressInterface,
      activeSorting: {} as Sorting,
      activeFilters: [] as Filter[],
      pagination: {} as Pagination,
    };
  },
  actions: {
    async list(pagination?: Pagination, filters: Filter[] = [], sorting?: Sorting, signal?: AbortSignal) {
      const users = await (new UserData()).list(pagination, filters, sorting, signal);
      this.users = users.map((user: UserInterface) => {
        return new User(user);
      });
    },
    async getCurrent() {
      try {
        const data = await (new UserData()).current();
        this.currentUser = new User(data.user);
        this.userOperations = data.operations;
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
        throw error;
      }
    },
    async getUser(userId:number) {
      try {
        const data = await (new UserData()).get(userId);
        this.selectedUser = new User(data);
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
        throw error;
      }
    },
    async getAddresses(userId: number, type?: 'shipping' | 'invoicing') {
      try {
        this.addresses = await (new UserData()).getAddresses(userId, type);
        if (type) {
          this[`${type}Addresses`] = this.addresses;
        }
      } catch (error) {
        ErrorHandlerService.handle(error);
        this.clear();
        throw error;
      }
    },
    clear() {
      this.users = [];
      this.currentUser = {} as User;
      this.selectedUser = {} as User;
      this.userOperations = {} as UserOperations;
      this.addresses = [] as AddressInterface[];
      this.selectedShippingAddress = {} as AddressInterface;
      this.selectedInvoicingAddress = {} as AddressInterface;
      this.activeSorting = {} as Sorting;
      this.activeFilters = [] as Filter[];
      this.pagination = {} as Pagination;
    },
  },
});
