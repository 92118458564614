<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="bg-white rounded-4 p-5">
    <div class="bi bi-send-check text-lavanda text-center" />
    <div class="fw-bold text-center my-3">
      Ordine inoltrato correttamente
    </div>
    <div class="text-center my-5">
      Ricordati di recarti nell’ordine per
      concludere la pratica confermando
      il pagamento e
      convalidare la dichiarazione di
      montaggio del Kit
    </div>
    <div
      v-if="orderStore.selectedPaymentMethod !== 'Paypal'"
      class="text-center"
    >
      <router-link
        v-for="order in orderStore.orders"
        :key="order.id"
        :to="{name: 'OrderDetail', params: {orderId: order.id}}"
      >
        <button class="btn btn-dark mb-2">
          {{ orderStore.orders.length > 1 ? `Ordine #${order.number}` : `Vai all'ordine` }}
        </button>
      </router-link>
    </div>
    <div v-else>
      <button
        class="btn btn-dark mb-2 w-100"
        :disabled="loading"
        @click="payWithPaypal"
      >
        <span
          v-if="loading"
          class="spinner-border"
        />
        <span v-else>Paga ordine</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useOrderStore} from '@/stores/OrderStore';
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default defineComponent({
  name: 'OrderCompletedWidget',
  setup() {
    return {
      orderStore: useOrderStore(),
    };
  },
  data() {
    return {
      loading: false as boolean,
    };
  },
  methods: {
    async payWithPaypal() {
      this.loading = true;
      try {
        const ordersIds = this.orderStore.orders.map((order) =>{
          return order.id;
        });
        const paypalLink = await this.$orderData.payWithPaypal(ordersIds);
        window.location.href = paypalLink.url;
      } catch (error) {
        this.loading = false;
        ErrorHandlerService.handle(error);
      }
    },
  },
});
</script>

<style scoped>
.bi{
  font-size: 40px;
}
</style>
