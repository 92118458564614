/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {DocumentInterface} from '@/types/entities';
import DocumentData from '@/services/data/DocumentData';

export default class DocumentDownloader {
  private documentData = new DocumentData();
  async download(document: DocumentInterface) {
    if (!document.id) {
      throw new Error('Document id is missing');
    }
    const blob = await this.documentData.download(document.id);
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement('a');
    link.href = url;
    link.download = document.filename;
    window.document.body.appendChild(link);
    link.click();
  }
}
