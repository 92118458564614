import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-637071dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table table-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bootstrap_table_head = _resolveComponent("bootstrap-table-head")!
  const _component_bootstrap_table_body = _resolveComponent("bootstrap-table-body")!
  const _component_bootstrap_table_foot = _resolveComponent("bootstrap-table-foot")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createVNode(_component_bootstrap_table_head, {
      modelValue: _ctx.tableProps,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tableProps) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_bootstrap_table_body, { "table-props": _ctx.tableProps }, null, 8, ["table-props"]),
    _createVNode(_component_bootstrap_table_foot, {
      modelValue: _ctx.tableProps,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tableProps) = $event))
    }, null, 8, ["modelValue"])
  ]))
}