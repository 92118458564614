<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div
    class="badge rounded-pill"
    :class="{ 'bg-sun-orange': !row.active, 'bg-earls-green': row.active }"
  >
    {{ row.active ? 'Attivo' : 'Non attivo' }}
  </div>
</template>


<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {Column, ProductInterface} from '@/types/entities';

export default defineComponent({
  name: 'ProductStatus',
  props: {
    row: {
      type: Object as PropType<ProductInterface>,
      required: true,
    },
    column: {
      type: Object as PropType<Column>,
      required: true,
    },
  },
});
</script>
