/**
 ************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************
 */

import {
  AddressInterface,
  CarrierInterface,
  OrderDetailInterface,
  OrderInterface, OrderPaymentMethod,
  OrderStatus,
  UserInterface,
} from '@/types/entities';
import {DateTime} from 'luxon';

export default class Order implements OrderInterface {
  id: number;
  invoicing: AddressInterface;
  number: number;
  orderDate: string;
  orderDetails: OrderDetailInterface[];
  courier: CarrierInterface | null;
  paymentId: string;
  paymentMethod: keyof typeof OrderPaymentMethod;
  processingDate: string | null;
  shipping: AddressInterface;
  netAmount: string;
  shippingCost: string;
  vatAmount: string;
  totalAmount: string;
  status: keyof typeof OrderStatus;
  trackId: string;
  carMaker: UserInterface;
  customer: UserInterface;
  vendor: UserInterface;
  address: {
    invoicing: AddressInterface,
    shipping: AddressInterface
  };
  newDocument: boolean;

  constructor(data: OrderInterface) {
    this.id = data.id;
    this.invoicing = data.invoicing;
    this.number = data.number;
    this.orderDate = data.orderDate;
    this.orderDetails = data.orderDetails;
    this.courier = data.courier || null;
    this.paymentId = data.paymentId;
    this.paymentMethod = data.paymentMethod;
    this.processingDate = data.processingDate;
    this.shipping = data.shipping;
    this.netAmount = data.netAmount;
    this.shippingCost = data.shippingCost;
    this.vatAmount = data.vatAmount;
    this.totalAmount = data.totalAmount;
    this.status = data.status;
    this.trackId = data.trackId;
    this.carMaker = data.carMaker;
    this.customer = data.customer;
    this.vendor = data.vendor;
    this.address = data.address;
    this.newDocument = data.newDocument;
  }

  get formattedOrderDate(): string {
    return DateTime.fromISO(this.orderDate).toLocaleString(DateTime.DATE_SHORT);
  }

  get formattedProcessingDate(): string {
    if (!this.processingDate) {
      return '';
    }
    return DateTime.fromISO(this.processingDate).toLocaleString(DateTime.DATE_SHORT);
  }

  get statusLabel(): string {
    return OrderStatus[this.status];
  }

  get paymentMethodLabel(): string {
    return OrderPaymentMethod[this.paymentMethod];
  }
}

export const TESTING_KM_0_CATEGORY = 'collaudi a km 0';
