<!--
/************************************************************************************
 * The contents of this file are subject to the "Condizioni generali di contratto per lo sviluppo di
 * applicazioni su commissione"; You may not use this file except in compliance with the Contract
 * The Initial Developer of the Original Code is EXA INNOVATION SRL
 * Portions created by EXA INNOVATION SRL are Copyright (C) EXA INNOVATION SRL
 * All Rights Reserved.
 ************************************************************************************/
-->

<template>
  <div class="d-flex">
    <span
      class="bi bi-dot fs-2"
      :class="statusColor"
    />
    <span> {{ order.statusLabel }} </span>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Order from '@/types/Order';

export default defineComponent({
  name: 'OrderStatus',
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  computed: {
    statusColor() {
      switch (this.order.status) {
        case 'created':
          return 'text-sun-orange';
        case 'payed':
          return 'text-amethyst';
        case 'shipped':
          return 'text-earls-green';
        case 'canceled':
          return 'text-red1';
        default:
          return 'text-gray';
      }
    },
  },
});
</script>

<style scoped lang="scss">
.bi.bi-dot {
  height: 0;
  line-height: 0;

  &:before {
    line-height: 0.75;
  }
}
</style>
